import React, {useEffect, useState} from "react";
import sjCircle from "../../assets/images/loginBackground/sj-circle.png";
import {Link, useNavigate, useParams, useSearchParams} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {clearErrorMessages, getPracticeId, logoutProvider, providerLogin, setPracticeId, setScreenName, setSlug} from "../../store/actions/account.action";
import {StateParams} from "../../store/reducers";
import { clearScreenerQuestions } from "../../store/actions/screenerQuestions.action";
import {MixpanelContext} from "../../analytics/Mixpanel";

export const ProviderLogin: React.FC<any> = () => {
  const analytics = React.useContext(MixpanelContext);
  const [email, setEmail] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const params = useParams();
  const [searchParams, setSearchParams] = useSearchParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {provider, providerSignInError, practiceId} = useSelector((state:StateParams) => state.account);

  const analyticsConfig = { };

  useEffect(() => {
    dispatch(clearErrorMessages())
    dispatch(clearScreenerQuestions())
  }, [])

  const doLogin = () => {
    if (email && password) {
      const user = {
        email,
        password
      }
      analytics?.track("admin_login", analyticsConfig)
      dispatch(providerLogin(user));
    }
  }

  useEffect(() => {
    if(provider && provider.jwt) {
      dispatch(setPracticeId({practiceId: provider.id}))
      analytics?.track("Login Success", analyticsConfig)
      dispatch(setScreenName({screenName: 'adminLogin'}))
      navigate("/patients?page=1")
    }
  }, [provider])

  return (
    <div className='h-screen mx-auto px-3 bg-sky-light-lg bg-no-repeat bg-cover'>
      <div className='w-full h-32 flex items-center justify-center'>
        <img src={sjCircle} className='h-[200px] mt-10' alt="sjCircle"/>
      </div>
      <div className="mt-16">
        <div className="w-full h-20 flex flex-col items-center justify-center">
          <label className="text-left">Username</label>
          <input data-test="providerUsernameInput" type="text" className="w-60"
                 onBlur={event => setEmail(event.target.value)}/>
        </div>
        <div className="w-full h-20 flex flex-col items-center justify-center">
          <label className="text-left">Password</label>
          <input data-test="providerPasswordInput" type="password" className="w-60"
                 onBlur={event => setPassword(event.target.value)}/>
        </div>
        <div className='w-full h-20 flex flex-col items-center justify-center'>
          <button data-test="provider-continue"
                  className='w-60 h-10 font-bold rounded-lg  bg-lightOrange text-white'
                  onClick={doLogin}>Continue
          </button>
        </div>
        <div className="flex justify-center mb-3">
        {providerSignInError &&
                  <span className='text-sm text-red-500 rounded-lg'>{providerSignInError}</span>}
        </div>
      </div>
    </div>
  )
}
