import { LanguageCodes } from "../../models";
import {
    CHECK_PHONE_NUMBER,
    CHECK_PHONE_NUMBER_FAILED,
    CHECK_PHONE_NUMBER_RESPONSE,
    CLEAR_PHONE_NUMBER_ERROR
} from "../actions/practice.action";

export interface PracticeParams {
    accountExistsWithPhoneNumber?: boolean
    phoneNumber?: string
    firstName?: string
    lastName?: string
    dob?: string
    checkPhoneExistsError?: string
    isPhoneVerificationRequired?: boolean
    languagePreferenceOnExistence?: LanguageCodes
}

const initialState = {
    phoneNumber: undefined,
    firstName: undefined,
    lastName: undefined,
    dob: undefined,
    accountExistsWithPhoneNumber: undefined,
    checkPhoneExistsError: undefined,
    isPhoneVerificationRequired: undefined,
    languagePreferenceOnExistence: undefined
}

const PracticeReducer = (state: PracticeParams = initialState, action: any): PracticeParams => {
    switch (action.type) {
        case CHECK_PHONE_NUMBER:
            state = {
                ...state, ...initialState}
            return state
        case CHECK_PHONE_NUMBER_RESPONSE:
            state = {
                ...state,
                accountExistsWithPhoneNumber: action.payload.exists,
                phoneNumber: action.payload.phoneNumber,
                firstName: action.payload.firstName,
                lastName: action.payload.lastName,
                dob: action.payload.dob,
                checkPhoneExistsError: undefined,
                languagePreferenceOnExistence: action.payload.preferredLanguage
            }
            return state
        case CHECK_PHONE_NUMBER_FAILED:
            state = { ...state, checkPhoneExistsError: action.payload.error }
            return state
        case CLEAR_PHONE_NUMBER_ERROR:
            state = { ...state, checkPhoneExistsError: undefined}
            return state
        default:
            return state;
    }
}

export default PracticeReducer
