import {ICheckIfPhoneNumberExists} from "../../models"

export const CHECK_PHONE_NUMBER = "CHECK_PHONE_NUMBER"
export const CHECK_PHONE_NUMBER_RESPONSE = "CHECK_PHONE_NUMBER_RESPONSE"
export const CHECK_PHONE_NUMBER_FAILED = "CHECK_PHONE_NUMBER_FAILED"
export const CLEAR_PHONE_NUMBER_ERROR = "CLEAR_PHONE_NUMBER_ERROR"

export const checkIfPhoneNumberExists = (phoneNumber: string, practiceId: string) => {
   return { type: CHECK_PHONE_NUMBER, payload: { phoneNumber, practiceId } }
}

export const accountExistsWithPhoneNumber = (payload: {phoneNumber: string} & ICheckIfPhoneNumberExists) => {
   return {type: CHECK_PHONE_NUMBER_RESPONSE, payload}
}

export const checkIfPhoneNumberExistsFailed = (payload: {error: string}) => {
   return {type: CHECK_PHONE_NUMBER_FAILED, payload}
}

export const clearPhoneNumberError = () => {
   return {type: CLEAR_PHONE_NUMBER_ERROR}
}
