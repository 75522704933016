import React, {useEffect} from "react";
import { useSelector } from "react-redux";
import { StateParams } from "../../store/reducers";
import { LottieComponent } from "../animations/LottieComponent";
import loadingAnimation from "../../assets/data/loadingAnimation.json"

export function LoadingComponent(props: any) {
    const { loading } = useSelector((state: StateParams) => state.loading)

    const loadingSection = () => {
        return (
            <section style={{ display: loading ? "block" : "none" }} className="loading-section w-full">
                <div style={{ position: "relative", top: "50%", left: "48%"}}>
                    <LottieComponent height={60} width={60} backgroundColor={"#FFC869"} borderRadius={"50%"} animationData={loadingAnimation} />
                </div>
            </section>
        )
    }

    return <>
        {loadingSection()}
        <>{props.children}</>
    </>
}
