import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import sjCircle from "../../assets/images/loginBackground/sj-circle.png";
import { resetPassword, setScreenName } from '../../store/actions/account.action';
import { StateParams } from '../../store/reducers';
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next';
import { LanguageCodes } from '../../models';

const ResetPassword: React.FC = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { t } = useTranslation();
  const { jwt, hasAcceptedTerms, languagePreference } = useSelector((state: StateParams) => state.account)
    const { phoneNumber } = useSelector((state: StateParams) => state.practice)
    const [data, setData] = useState({
        phoneNumber: phoneNumber,
        password: ""
    })
    const [password, setPassword] = useState('')
    const [confirmPassword, setConfirmPassword] = useState('')

    const onChangeValue = (e: any) => {
        setData({ ...data, [e.target.id]: e.target.value })
    }

    const handleSubmit = (e: any) => {
        e.preventDefault()
        if (phoneNumber && confirmPassword && password && confirmPassword === password && password.length > 7) {
            dispatch(resetPassword({
                phoneNumber: phoneNumber,
                password: password
            }))
        }
    }

    useEffect(() => {
        if (jwt && hasAcceptedTerms) {
            dispatch(setScreenName({screenName: 'resetPass'}))
            navigate('/screenerQuestion')
        } else if (jwt && !hasAcceptedTerms) {
            navigate('/termsAndCondition')
        }
    }, [jwt, hasAcceptedTerms])
      
    return (<>
        <form onSubmit={handleSubmit}>
            <div className='h-screen mx-auto px-3 bg-sky-light-lg bg-no-repeat bg-cover'>
                <div className='w-full h-32 flex items-center justify-center'>
                    <img src={sjCircle} className='h-[200px] mt-10' />
                </div>
                <div className='w-full h-32 mt-20 flex flex-col items-center justify-center'>
                    <p className='text-lg font-bold'>{t('resetPassword')}</p>
                    <span className='text-8xs text-center'>{t('resetPasswordSubTitle')}</span>
                    <input required className='mt-10' placeholder={languagePreference === LanguageCodes.EN_US ? 'New password' : 'Nueva contraseña'} type={"password"} name={"newPassword"} onChange={(event: React.ChangeEvent<HTMLInputElement>) => { setPassword(event.target.value) }} />
                    <input required className='mt-3' placeholder={languagePreference === LanguageCodes.EN_US ? 'Confirm password' : 'Confirmar contraseña'} type={"password"} name={"confirmPassword"} onChange={(event: React.ChangeEvent<HTMLInputElement>) => { setConfirmPassword(event.target.value) }} />
                </div>
                <div className='w-full h-32 mt-10 flex flex-col items-center justify-center'>
                    <button className='w-64 h-10 font-bold rounded-lg  bg-lightOrange text-white' onClick={() => { }}>{t('resetPassword')}</button>
                    {confirmPassword && password && confirmPassword !== password && <span className='text-sm text-red-700 rounded-lg dark:text-red-800'>Passwords don't match!</span>}
                    {password.length < 8 && <span className='text-sm text-red-700 rounded-lg dark:text-red-800'>Your password should have at least 8 characters</span>}
                    {/* {!phoneNumber && <span className='text-sm text-red-700 rounded-lg dark:text-red-800'>Reset password failed!</span>} */}
                </div>
            </div>
        </form>
    </>)
}

export default ResetPassword