import { useEffect, useRef } from "react"

import lottie from "lottie-web"

interface LottieProps {
    animationData: any
    width: number
    height: number
    backgroundColor?: string
    borderRadius?: string
}

export const LottieComponent: React.FC<LottieProps> = ({ animationData, width, height, backgroundColor, borderRadius }) => {
    const element = useRef<HTMLDivElement>(null)
    const lottieInstance = useRef<any>()

    useEffect(() => {
        if (element.current) {
            lottieInstance.current = lottie.loadAnimation({
                animationData,
                container: element.current,
            })
        }
        return () => {
            lottie.destroy()
        }
    }, [animationData, element.current])

    return (<div style={{ width, height, backgroundColor, borderRadius }} ref={element} />)
}