import React, { useState, useEffect } from "react";
import { Button, PageButton } from "./Buttons";
import {ChevronDoubleLeftIcon, ChevronLeftIcon, ChevronRightIcon, ChevronDoubleRightIcon} from '@heroicons/react/20/solid'
import { useNavigate } from "react-router-dom";

type PaginationProps = {
    totalRows: number;
    rowsPerPage: number
    currentPage: number
    recordsPerPageHandler: (recordsPerPage: number) => any
    currentPageHandler: (currentPage: number) => any
}

const Pagination: React.FC<PaginationProps> = ({currentPage, currentPageHandler, totalRows, rowsPerPage, recordsPerPageHandler  }) => {
    const navigate = useNavigate()

    // Calculating max number of pages
    const noOfPages = Math.ceil(totalRows / rowsPerPage);

    // Creating an array with length equal to no.of pages
    const pagesArr = [...new Array(noOfPages)];

    // State variable to hold the current page. This value is
    // passed to the callback provided by the parent
    // const [currentPage, setCurrentPage] = useState(1);

    // Navigation arrows enable/disable state
    const [canGoBack, setCanGoBack] = useState(false);
    const [canGoNext, setCanGoNext] = useState(true);

    // Onclick handlers for the butons
    const onNextPage = () => currentPageHandler(currentPage + 1);
    const onPrevPage = () => currentPageHandler(currentPage - 1);
    const onPageSelect = (pageNo: number) => currentPageHandler(pageNo);

    // Disable previous and next buttons in the first and last page
    // respectively
    useEffect(() => {
        if (noOfPages === currentPage) {
            setCanGoNext(false);
        } else {
            setCanGoNext(true);
        }
        if (currentPage === 1) {
            setCanGoBack(false);
        } else {
            setCanGoBack(true);
        }
    }, [noOfPages, currentPage]);

    // To set the starting index of the page
    

    return (<>
        <div className="py-3 flex items-center justify-between">
                <div className="flex-1 flex justify-between sm:hidden">
                    <Button onClick={onPrevPage} disabled={!canGoBack}>Previous</Button>
                    <Button onClick={onNextPage} disabled={!canGoNext}>Next</Button>
                </div>
                <div className="hidden sm:flex-1 sm:flex sm:items-center sm:justify-between">
                    <div className="flex gap-x-2">
                        <span className="text-sm text-gray-700">
                            {/* @ts-ignore */}
                            Page <span className="font-medium">{currentPage}</span> of <span className="font-medium">{noOfPages}</span>{' '}
                            {/* @ts-ignore */}
                            <select value={rowsPerPage} className={"rounded-full focus:border-orange-300 focus:ring focus:ring-orange-200 focus:ring-opacity-50"} 
                            onChange={e => { recordsPerPageHandler(Number(e.target.value)) 
                                            currentPageHandler(1)
                                    }} >
                                {[10,20,30].map(pageSize => (
                                    <option key={pageSize} value={pageSize}>
                                        Show {pageSize}
                                    </option>
                                ))}
                            </select>
                        </span>
                    </div>
                    <div>
                        <nav className="relative z-0 inline-flex rounded-md shadow-sm -space-x-px" aria-label="Pagination">
                            <PageButton
                                className="rounded-l-md"
                                onClick={() => onPageSelect(1)}
                                disabled={!canGoBack}
                            >
                                <span className="sr-only">First</span>
                                <ChevronDoubleLeftIcon className="h-5 w-5" aria-hidden="true" />
                            </PageButton>
                            <PageButton
                                onClick={onPrevPage}
                                disabled={!canGoBack}
                            >
                                <span className="sr-only">Previous</span>
                                <ChevronLeftIcon className="h-5 w-5" aria-hidden="true" />
                            </PageButton>
                            <PageButton
                                onClick={onNextPage}
                                disabled={!canGoNext
                                }>
                                <span className="sr-only">Next</span>
                                <ChevronRightIcon className="h-5 w-5" aria-hidden="true" />
                            </PageButton>
                            <PageButton
                                className="rounded-r-md"
                                onClick={() => onPageSelect(noOfPages)}
                                disabled={!canGoNext}
                            >
                                <span className="sr-only">Last</span>
                                <ChevronDoubleRightIcon className="h-5 w-5" aria-hidden="true" />
                            </PageButton>
                        </nav>
                    </div>
                </div>
            </div>
    </>)
}

export default Pagination