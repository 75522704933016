import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import {useNavigate} from 'react-router-dom'
import sjCircle from '../../assets/images/loginBackground/sj-circle.png'
import { StateParams } from '../../store/reducers'

const BeforeAdhdNote: React.FC<{}> = () => {
    const navigate = useNavigate()
    const { t } = useTranslation();

    const { tobeAnsweredQuestion } = useSelector((state: StateParams) => state.screener)
    const { userScreenerId } = useSelector((state: StateParams) => state.account)

    const handleClick = () => {
        if (tobeAnsweredQuestion?.id) {
            const questionId = tobeAnsweredQuestion.id
            navigate(`/screener/${userScreenerId}/question/${questionId}`)
        }
    }

    return (
        <div className='h-screen px-3 bg-sky-light-lg bg-no-repeat bg-cover'>
            <div className='w-full h-32 flex items-center justify-center'>
                <img src={sjCircle} className='h-[200px] mt-10' />
            </div>
            <div className='mt-20'>
                <p data-test="note-above12-title" className='text-md font-bold text-center'>{t('adhdNote')}</p>
            </div>
            <div className='mt-10'>
                <button data-test="note-above12-parent" className='bg-orange text-white font-bold p-2 rounded-xl w-full my-2' type='submit' onClick={() => handleClick()}>
                    {t('handoverButton')}
                </button>
            </div>
        </div>
    )
}

export default BeforeAdhdNote