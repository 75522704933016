import React from 'react'
import { FaHome, FaPowerOff } from 'react-icons/fa'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { logoutUser } from '../../store/actions/account.action'
import { StateParams } from '../../store/reducers'
import { FiPhoneCall } from 'react-icons/fi'
import { FaChrome } from 'react-icons/fa'
import { useTranslation } from 'react-i18next'
import sjCircle from '../../assets/images/loginBackground/sj-circle.png'

const SuicidalHelpline = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const { t } = useTranslation();
    const { jwt } = useSelector((state: StateParams) => state.account)

    const logout = () => {
        dispatch(logoutUser())
    }

    const home = () => {
        navigate('/patients?page=1')
    }

    return (
        <div className='h-screen mx-auto px-3 bg-sky-light-lg bg-no-repeat bg-cover'>
            <div className='w-full flex justify-between'>
                {jwt ? <></> : <FaHome className='mt-2' onClick={home} />}
                <FaPowerOff data-tooltip-target="tooltip-bottom" data-tooltip-placement="bottom" className='m-2' onClick={logout} />
            </div>
            <div className='w-full h-32 flex items-center justify-center'>
                <img src={sjCircle} className='h-[200px]' />
            </div>
            <p className='m-6 text-2xl font-bold flex text-center'>{t('suicidalTitle')}</p>
            <p className='my-10 text-sm flex text-center'>{t('suicidalSubTitle')}</p>
            <p className='flex items-center justify-center mt-10'><FiPhoneCall className='mx-2' />{t('call')}</p>
            <p className='flex items-center justify-center mt-2 font-bold text-orange text-lg'>800-273-8255</p>
            <p className='flex items-center justify-center mt-6'><FaChrome className='mx-2' />{t('officialWebsite')}</p>
            <p className='flex items-center justify-center mt-2 font-bold text-orange text-lg'>suicidepreventionlifeline.org</p>
        </div>
    )
}

export default SuicidalHelpline