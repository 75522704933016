import React from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import welcomeLogo from '../../assets/images/welcomeImage/welcomeLogo.png'
import { setScreenName } from '../../store/actions/account.action'
import { StateParams } from '../../store/reducers'

const Welcome = () => {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const { t } = useTranslation();
    const { jwt, screenName } = useSelector((state: StateParams) => state.account)
  
    const onClickStart = () => {
        if(screenName){
            navigate('/termsAndCondition')
        } else {
            dispatch(setScreenName({screenName: 'welcome'}))
            navigate('/termsAndCondition')
        }
    }

    return (
        <div className='h-screen mx-auto px-3 bg-sky-light-lg bg-no-repeat bg-cover'>
            <div className='w-full h-32 flex items-center justify-center'>
                <img src={welcomeLogo} className='h-[130px] mt-20' alt="welcomeLogo"/>
            </div>
            <p data-test="welcome-title" className='flex items-center justify-center text-lg font-bold mt-24'>{t('welcomeTitle')}</p>
            <p className='text-sm pl-4 pr-4 mt-5'>{t('welcomeSubTitle')}</p>
            <button className='bg-orange text-white font-bold p-2 rounded-xl w-full mt-20' onClick={onClickStart}>
                {t('getStartedButton')}
            </button>
        </div>
    )
}

export default Welcome