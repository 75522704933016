import {
    AllScreenerQuestion,
    IPatient,
    IPatientDetails,
    IPatientResponse, IPatientSummary,
    IScreenerQuestion,
    LanguageCodes
} from "../../models"
import { SET_NEXT_USER_SCREENER_ID } from "../actions/account.action";
import {
    FETCHED_CURRENT_QUESTION,
    FAILED_FETCHING_CURRENT_QUESTION,
    FETCHED_QUESTION_DETAILS,
    FAILED_FETCHING_QUESTION_DETAILS,
    SAVED_USER_SCREENER_ANSWER,
    FAILED_SAVING_USER_SCREENER_ANSWER,
    SET_NEXT_QUESTION_LINK,
    FETCH_PATIENTS_SUCCESS,
    FETCH_PATIENTS_FAILED,
    SAVED_LANGUAGE_PREFERENCE,
    SAVING_LANGUAGE_PREFERENCE_FAILED,
    CLEAR_SCREENER_QUESTIONS,
    FETCH_PATIENT_SUCCESS, FETCH_PATIENT_FAILED
} from "../actions/screenerQuestions.action";


export interface ScreenerParams {
    tobeAnsweredQuestion?: IScreenerQuestion
    isScreenerCompleted?: boolean
    allScreenerQuestions?: AllScreenerQuestion[]
    errorFetchingCurrentQuestion?: string
    currentQuestionDetails?: IScreenerQuestion
    errorFetchingQuestionDetails?: string
    nextQuestionLink?: string
    nextScreenerId?: string
    nextScreenerSlug?: string
    errorSavingAnswer?: string
    isScreenerPositive?: boolean
    patients?: IPatientSummary[]
    patient?: IPatientDetails
    recordsCount?: number
    errorFetchingPatients?: string
    errorFetchingPatient?: string
    languagePreferenceOnAdminFlow?: LanguageCodes
    errorSavingLanguage?: string
}
const initialState: ScreenerParams = {
    tobeAnsweredQuestion: undefined,
    currentQuestionDetails: undefined,
    nextQuestionLink: undefined,
    allScreenerQuestions: undefined,
    patients: undefined,
    patient: undefined,
    recordsCount: undefined,
    errorFetchingPatients: undefined,
    languagePreferenceOnAdminFlow: undefined,
    errorSavingLanguage: undefined
}

const ScreenerQuestionsReducer = (state: ScreenerParams = initialState, action: any) => {
    switch (action.type) {
        case FETCHED_CURRENT_QUESTION:
            state = { ...state, tobeAnsweredQuestion: action.payload.tobeAnsweredQuestion, isScreenerCompleted: action.payload.isScreenerCompleted, allScreenerQuestions: action.payload.allScreenerQuestions }
            return state
        case FAILED_FETCHING_CURRENT_QUESTION:
            state = { ...state, errorFetchingCurrentQuestion: action.payload.error }
            return state
        case FETCHED_QUESTION_DETAILS:
            state = { ...state, currentQuestionDetails: action.payload.currentQuestionDetails, allScreenerQuestions: action.payload.allScreenerQuestions }
            return state
        case FAILED_FETCHING_QUESTION_DETAILS:
            state = { ...state, errorFetchingQuestionDetails: action.payload.error }
            return state
        case SAVED_USER_SCREENER_ANSWER:
            state = { ...state, nextQuestionLink: action.payload.nextQuestionLink, nextScreenerId: action.payload.nextScreenerId, nextScreenerSlug: action.payload.nextScreenerSlug, isScreenerPositive: action.payload.isScreenerPositive }
            return state
        case FAILED_SAVING_USER_SCREENER_ANSWER:
            state = { ...state, errorSavingAnswer: action.payload.error }
            return state
        case SET_NEXT_USER_SCREENER_ID:
            state = { ...state, nextQuestionLink: undefined, tobeAnsweredQuestion: undefined }
            return state
        case SET_NEXT_QUESTION_LINK:
            state = { ...state, nextQuestionLink: undefined }
            return state
        case FETCH_PATIENTS_SUCCESS:
            state = { ...state, patients: action.payload.patients, recordsCount: action.payload.recordsCount }
            return state
        case FETCH_PATIENTS_FAILED:
            state = { ...state, errorFetchingPatients: action.payload.error }
            return state
        case FETCH_PATIENT_SUCCESS:
            state = { ...state, patient: action.payload.patient}
            return state
        case FETCH_PATIENT_FAILED:
            state = { ...state, errorFetchingPatient: action.payload.error }
            return state
        case SAVED_LANGUAGE_PREFERENCE:
            state = { ...state, languagePreferenceOnAdminFlow: action.payload.preferredLanguage }
            return state
        case SAVING_LANGUAGE_PREFERENCE_FAILED:
            state = { ...state, errorSavingLanguage: action.payload.error }
            return state
        case CLEAR_SCREENER_QUESTIONS:
            state = { ...state, ...initialState}
            return state
        default:
            return state;
    }
}

export default ScreenerQuestionsReducer
