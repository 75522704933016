import {combineReducers} from "redux";
import AccountReducer, { AccountParams } from "./account.reducer";
import LoadingReducer, { LoadingParams } from "./loading.reducer";
import ScreenerQuestionsReducer, { ScreenerParams } from "./screenerQuestions.reducer";
import PracticeReducer, {PracticeParams} from "./practice.reducer";
import SignupReducer, { SignupParams } from "./signupData.reducer";

export interface StateParams {
    screener: ScreenerParams
    account: AccountParams
    loading: LoadingParams
    practice: PracticeParams
    signup: SignupParams
}

const rootReducer = combineReducers({
    account: AccountReducer,
    screener: ScreenerQuestionsReducer,
    loading: LoadingReducer,
    practice: PracticeReducer,
    signup: SignupReducer
})


export default rootReducer;
