import { REHYDRATE } from "redux-persist";
import {
    CALL_IN_PROGRESS,
    CALL_FINISHED
} from "../actions/loading.action";

export interface LoadingParams {
    loading: boolean
    event?: string
    rehydrate: boolean
}

const initialState: LoadingParams = {
    loading: false,
    rehydrate: false
}

const LoadingReducer = (state: LoadingParams = initialState, action: any) : LoadingParams => {

    switch (action.type) {
        case REHYDRATE:
            state = {...state, rehydrate: true}
            return state
        case CALL_IN_PROGRESS:
            state = {...state, loading: true, event: action.event}
            return state
        case CALL_FINISHED:
            state = {...state, loading: false, event: action.event}
            return state
        default:
            return state;
    }

}

export default LoadingReducer
