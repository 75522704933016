import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useNavigate } from 'react-router-dom'
import sjCircle from '../../assets/images/loginBackground/sj-circle.png'
import { accountSignin, setScreenName } from '../../store/actions/account.action'
import { saveLanguagePreference } from '../../store/actions/screenerQuestions.action'
import { StateParams } from '../../store/reducers'

const Login = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { t } = useTranslation();
  const { jwt, practiceId, signInError, hasAcceptedTerms, languagePreference } = useSelector((state: StateParams) => state.account)
  const { phoneNumber } = useSelector((state: StateParams) => state.practice)
  const [data, setData] = useState({
    phoneNumber: "",
    password: ""
  })

  const onChangeValue = (e: any) => {
    setData({ ...data, [e.target.id]: e.target.value })
  }

  useEffect(() => {
    if (phoneNumber) {
      setData({ ...data, phoneNumber: phoneNumber })
    }
  }, [phoneNumber])

  const handleSubmit = (e: any) => {
    e.preventDefault()
    dispatch(accountSignin({
      phoneNumber: data.phoneNumber,
      password: data.password
    }))
  }
  useEffect(() => {
    if (practiceId === undefined) {
      navigate('/')
    }
  }, [practiceId])

  useEffect(() => {
    if (jwt) {
      dispatch(saveLanguagePreference({ preferredLanguage: languagePreference }))
      if (hasAcceptedTerms) {
        dispatch(setScreenName({screenName: 'login'}))
        navigate('/screenerQuestion')
      } else if (!hasAcceptedTerms) {
        dispatch(setScreenName({screenName: 'loginT&C'}))
        navigate('/termsAndCondition')
      }
    }
  }, [jwt, hasAcceptedTerms])

  return (
    <div className='h-screen md:max-w-[768px] mx-auto px-3 bg-sky-light-lg bg-no-repeat bg-cover'>
      <div className='w-full h-32 flex items-center justify-center'>
        <img src={sjCircle} className='h-[200px] mt-10' alt="sjCircle" />
      </div>
      <p className='text-lg font-bold mt-10'>{t('loginTitle')}</p>
      <p className='text-xs'>{t('loginSubTitle')}</p>
      <div className='mt-3'>
        <form onSubmit={handleSubmit}>
          <div>
            <label className='text-xs'>{t('mobileNumber')}</label>
            <input id='phoneNumber' type='text' disabled required onChange={(e) => onChangeValue(e)}
              value={data.phoneNumber}
              className='w-full rounded-md h-8 bg-whiteSmoke border-gray-300 focus:outline-none focus:border-orange focus:ring-1 focus:ring-orange' />
          </div>
          <div className='mb-10'>
            <label className='text-xs'>{t('password')}</label>
            <input id='password' type='password' onChange={(e) => onChangeValue(e)} required
              className='w-full rounded-md h-8 border-gray-300 focus:outline-none focus:border-orange focus:ring-1 focus:ring-orange' />
          </div>

          <div className='flex flex-col'>
            <button className='bg-orange text-white font-bold p-2 rounded-xl w-full mt-2' type='submit' value="Submit">
              {t('proceedButton')}
            </button>
            <div>
              {signInError &&
                <span className='text-sm text-center text-red-700 rounded-lg dark:text-red-800'>{signInError}</span>}
              <span
                className='text-8xs font-bold float-right	pt-1 text-hyperLink hover:text-blue-800 hover:cursor-pointer visited:text-purple-600'
                onClick={() => {
                  navigate('/forgotPassword')
                }}>{t('forgotPassword')}
              </span>
            </div>
          </div>
        </form>
      </div>
    </div>
  )
}

export default Login