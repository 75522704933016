import Alcohol from "../../components/screener/context/Alcohol";


type UserConfig = {
    topBackground: string;
    backgroundColor: string;
    bottomBackground: string;
    bottomImageHeight?: string;
    progressDarkColor?: string;
    progressLightColor?: string;
    headerContentFontColor?: string;
    questionContentFontColor?: string;
    contextComponent?: any
}
type StyleSetters = {
    setTopBackground: (background: string) => void;
    setBackgroundColor: (background: string) => void;
    setBottomBackground: (background: string) => void;
    setBottomImageHeight: (height: string) => void;
    setProgressDarkColor: (color: string) => void;
    setProgressLightColor: (color: string) => void;
    setHeaderContentFontColor: (color: string) => void;
    setQuestionContentFontColor: (color: string) => void;
    setContextComponent: (component?: React.ComponentType<any>) => void;
}

type ConfigMap = {
    [key: string]: {
        slugs: string[]
        config: UserConfig
    }
}

const getScreenerConfigBySlug = (slug: string): UserConfig | null => {
    const matchingKey = Object.keys(configMap).find(key => {
        return configMap[key].slugs.includes(slug)
    })
    return matchingKey ? configMap[matchingKey].config : null
}

const applyScreenerStyles = (config: UserConfig | null, setters: StyleSetters) => {
    if (!config) return;

    const {
        topBackground,
        backgroundColor,
        bottomBackground,
        bottomImageHeight = '',
        progressDarkColor = '',
        progressLightColor = '',
        headerContentFontColor = '',
        questionContentFontColor = 'text-paleRed',
        contextComponent
    } = config;

    setters.setTopBackground(topBackground);
    setters.setBackgroundColor(backgroundColor);
    setters.setBottomBackground(bottomBackground);
    setters.setBottomImageHeight(bottomImageHeight);
    setters.setProgressDarkColor(progressDarkColor);
    setters.setProgressLightColor(progressLightColor);
    setters.setHeaderContentFontColor(headerContentFontColor);
    setters.setQuestionContentFontColor(questionContentFontColor);
    setters.setContextComponent(contextComponent);
}

const configMap: ConfigMap = {
    psc: {
        slugs: ['psc-6to12', 'psc-y', 'fp-psc-6to12', 'fp-psc-y'],
        config: {
            topBackground: 'bg-sun-sky-lg',
            backgroundColor: 'bg-paleBackgroundYellow',
            bottomBackground: 'bg-birds-lg',
            bottomImageHeight: 'h-[8px]',
            progressDarkColor: 'bg-paleRed',
            progressLightColor: 'bg-paleOrange',
        },
    },
    anxiety: {
        slugs: ['anxiety', 'anxiety-SCARED', 'anxiety-50to150', 'fp-anxiety-SCARED', 'fp-anxiety', 'fp-anxiety-above18'],
        config: {
            topBackground: 'bg-anxiety-city-top-lg',
            backgroundColor: 'bg-paleBackgroundBlue',
            bottomBackground: 'bg-anxiety-bottom-lg',
            bottomImageHeight: 'h-[8px]',
            progressDarkColor: 'bg-blue',
            progressLightColor: 'bg-paleBlue',
            headerContentFontColor: 'text-[#554F7A]',
            questionContentFontColor: 'text-[#0C80E3]',
        },
    },
    depression: {
        slugs: ['depression', 'depression-MFQ', 'fp-depression', 'fp-depression-MFQ'],
        config: {
            topBackground: 'bg-depression-winter-landscape-lg',
            backgroundColor: 'bg-paleBackgroundPurple',
            bottomBackground: 'bg-depression-bottom-lg',
            progressDarkColor: 'bg-purple',
            progressLightColor: 'bg-palePurple',
        }
    },
    suicidal: {
        slugs: ['suicidal', 'fp-suicidal', 'fp-phq9-suicidal'],
        config: {
            topBackground: 'bg-suicidal-mountain-top-lg',
            backgroundColor: 'bg-paleBackgroundGreen',
            bottomBackground: 'bg-suicidal-bottom-lg',
            progressDarkColor: 'bg-green',
            progressLightColor: 'bg-paleGreen',
        }
    },
    aceBelow12: {
        slugs: ['ace-below6', 'ace-6to12', 'fp-ace-below6', 'fp-ace-6to12'],
        config: {
            topBackground: 'bg-ace-child-top-lg',
            backgroundColor: 'bg-paleBackgroundViolet',
            bottomBackground: 'bg-ace-bottom-lg',
            progressDarkColor: 'bg-purple',
            bottomImageHeight: 'h-[8px]',
        }
    },
    aceAbove18: {
        slugs: ['ace-12to18', 'ace-above18', 'fp-ace-12to18', 'fp-ace-above18', 'fp-slesq-above18', 'slesq-50to150', 'slesq-above18'],
        config: {
            topBackground: 'bg-ace-teen-top-lg',
            backgroundColor: 'bg-paleBackgroundAceYellow',
            bottomBackground: 'bg-ace-bottom-lg',
            progressDarkColor: 'bg-purple',
            bottomImageHeight: 'h-[8px]',
        }
    },
    adhd: {
        slugs: ['adhd-6to12', 'adhd-12to25', 'fp-adhd-6to12', 'fp-adhd-12to18'],
        config: {
            topBackground: 'bg-adhd-top-lg',
            backgroundColor: 'bg-paleBackgroundAdhdGreen',
            bottomBackground: 'bg-ace-bottom-lg',
            progressDarkColor: 'bg-purple',
            bottomImageHeight: 'h-[8px]',
        }
    },
    phq9: {
        slugs: ['phq-9', 'phq-9-50to150', 'fp-phq-9-above18', 'fp-phq-9'],
        config: {
            topBackground: 'bg-phq-9-top',
            backgroundColor: 'bg-paleBackgroundphq9',
            bottomBackground: 'bg-ace-bottom-lg',
            progressDarkColor: 'bg-purple',
            bottomImageHeight: 'h-[8px]',
            headerContentFontColor: 'text-[#554F7A]'
        }
    },
    twoMonth: {
        slugs: ['fp-two-month'],
        config: {
            topBackground: 'bg-two-month-top',
            backgroundColor: 'bg-paleBackgroundYellow',
            bottomBackground: 'bg-ace-bottom-lg',
            progressDarkColor: 'bg-purple',
            bottomImageHeight: 'h-[8px]',
        }
    },
    fourMonth: {
        slugs: ['fp-four-month'],
        config: {
            topBackground: 'bg-four-month-top',
            backgroundColor: 'bg-paleBackgroundPurple',
            bottomBackground: 'bg-ace-bottom-lg',
            progressDarkColor: 'bg-purple',
            bottomImageHeight: 'h-[8px]',
        }
    },
    sixMonth: {
        slugs: ['fp-six-month'],
        config: {
            topBackground: 'bg-six-month-top',
            backgroundColor: 'bg-paleBackgroundPurple',
            bottomBackground: 'bg-ace-bottom-lg',
            progressDarkColor: 'bg-purple',
            bottomImageHeight: 'h-[8px]',
        }
    },
    nineMonth: {
        slugs: ['fp-nine-month'],
        config: {
            topBackground: 'bg-nine-month-top',
            backgroundColor: 'bg-paleBackgroundYellow',
            bottomBackground: 'bg-ace-bottom-lg',
            progressDarkColor: 'bg-purple',
            bottomImageHeight: 'h-[8px]',
        }
    },
    twelveMonth: {
        slugs: ['fp-twelve-month'],
        config: {
            topBackground: 'bg-twelve-month-top',
            backgroundColor: 'bg-paleBackgroundYellow',
            bottomBackground: 'bg-ace-bottom-lg',
            progressDarkColor: 'bg-purple',
            bottomImageHeight: 'h-[8px]',
        }
    },
    fifteenMonth: {
        slugs: ['fp-fifteen-month'],
        config: {
            topBackground: 'bg-fifteen-month-top',
            backgroundColor: 'bg-paleBackgroundGreen',
            bottomBackground: 'bg-ace-bottom-lg',
            progressDarkColor: 'bg-purple',
            bottomImageHeight: 'h-[8px]',
        }
    },
    eighteenMonth: {
        slugs: ['fp-eighteen-month'],
        config: {
            topBackground: 'bg-eighteen-month-top',
            backgroundColor: 'bg-paleBackgroundPurple',
            bottomBackground: 'bg-ace-bottom-lg',
            progressDarkColor: 'bg-purple',
            bottomImageHeight: 'h-[8px]',
        }
    },
    twentyFourMonth: {
        slugs: ['fp-twentyfour-month'],
        config: {
            topBackground: 'bg-twenty-four-month-top',
            backgroundColor: 'bg-paleBackgroundAceYellow',
            bottomBackground: 'bg-ace-bottom-lg',
            progressDarkColor: 'bg-purple',
            bottomImageHeight: 'h-[8px]',
        }
    },
    thirtyMonth: {
        slugs: ['fp-thirty-month'],
        config: {
            topBackground: 'bg-thirty-month-top',
            backgroundColor: 'bg-paleBackgroundAdhdGreen',
            bottomBackground: 'bg-ace-bottom-lg',
            progressDarkColor: 'bg-purple',
            bottomImageHeight: 'h-[8px]',
        }
    },
    thirtySixMonth: {
        slugs: ['fp-thirtysix-month'],
        config: {
            topBackground: 'bg-thirty-six-month-top',
            backgroundColor: 'bg-paleBackgroundAdhdGreen',
            bottomBackground: 'bg-ace-bottom-lg',
            progressDarkColor: 'bg-purple',
            bottomImageHeight: 'h-[8px]',
        }
    },
    fortyEightMonth: {
        slugs: ['fp-fortyeight-month'],
        config: {
            topBackground: 'bg-forty-eight-month-top',
            backgroundColor: 'bg-paleBackgroundAdhdGreen',
            bottomBackground: 'bg-ace-bottom-lg',
            progressDarkColor: 'bg-purple',
            bottomImageHeight: 'h-[8px]',
        }
    },
    sixtyMonth: {
        slugs: ['fp-sixty-month'],
        config: {
            topBackground: 'bg-sixty-month-top',
            backgroundColor: 'bg-paleBackgroundAdhdGreen',
            bottomBackground: 'bg-ace-bottom-lg',
            progressDarkColor: 'bg-purple',
            bottomImageHeight: 'h-[8px]',
        }
    },
    mChatR: {
        slugs: ['fp-m-chat-r'],
        config: {
            topBackground: 'bg-m-chat-r-top',
            backgroundColor: 'bg-paleBackgroundGreen',
            bottomBackground: 'bg-ace-bottom-lg',
            progressDarkColor: 'bg-green',
            bottomImageHeight: 'h-[8px]',
        }
    },
    fpTaps: {
        slugs: ['fp-taps'],
        config: {
            topBackground: 'bg-taps-top',
            backgroundColor: 'bg-paleBackgroundGreen',
            bottomBackground: 'bg-ace-bottom-lg',
            progressDarkColor: 'bg-green',
            bottomImageHeight: 'h-[8px]',
        }
    },
    fpQuestionnaire: {
        slugs: ['fp-questionnaire-above18'],
        config: {
            topBackground: 'bg-questionnaire-top',
            backgroundColor: 'bg-antinqueWhite',
            bottomBackground: 'bg-ace-bottom-lg',
            progressDarkColor: 'bg-green',
            bottomImageHeight: 'h-[8px]',
            headerContentFontColor: 'text-[#F18058]',
            questionContentFontColor: 'text-[#6E5048]'
        }
    },
    fpAlcoholAbove18: {
        slugs: ['fp-alcohol-above18'],
        config: {
            topBackground: 'bg-questionnaire-top',
            backgroundColor: 'bg-antinqueWhite',
            bottomBackground: 'bg-ace-bottom-lg',
            progressDarkColor: 'bg-green',
            bottomImageHeight: 'h-[8px]',
            headerContentFontColor: 'text-[#F18058]',
            questionContentFontColor: 'text-[#6E5048]',
            // contextComponent: Alcohol
        }
    },
    fpDrug: {
        slugs: ['fp-drug-above18'],
        config: {
            topBackground: 'bg-drug-top',
            backgroundColor: 'bg-drugBlue',
            bottomBackground: 'bg-ace-bottom-lg',
            progressDarkColor: 'bg-green',
            bottomImageHeight: 'h-[8px]',
        }
    },
    fpPtsd: {
        slugs: ['fp-ptsd-above18'],
        config: {
            topBackground: 'bg-ptsd-top',
            backgroundColor: 'bg-cosmicGreen',
            bottomBackground: 'bg-ace-bottom-lg',
            progressDarkColor: 'bg-emeraldGreen',
            bottomImageHeight: 'h-[8px]',
        }
    }
}

export {
    getScreenerConfigBySlug,
    applyScreenerStyles
}