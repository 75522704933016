import React, { useCallback, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { StateParams } from "../../store/reducers";
import 'react-phone-number-input/style.css'
import { saveLanguagePreference } from "../../store/actions/screenerQuestions.action";
import withPreferredLanguageSelection from "./withPreferredLanguageSelection";

const AdminPreferredLanguageSelection: React.FC = () => {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const { checkPhoneExistsError } = useSelector((state: StateParams) => state.practice)
    const { languagePreference } = useSelector((state: StateParams) => state.account)
    const { languagePreferenceOnAdminFlow } = useSelector((state: StateParams) => state.screener)

    const submitPreferredLanguage = useCallback(() => {
        if (languagePreference) {
            dispatch(saveLanguagePreference({ preferredLanguage: languagePreference }))
        }
    }, [languagePreference])

    useEffect(() => {
        if (languagePreferenceOnAdminFlow) {
            navigate("/screenerQuestion")
        }
    }, [languagePreferenceOnAdminFlow])

    return (
        <>
            <div className='w-full flex flex-col items-center justify-center'>
                <button data-test="practice-continue"
                    className='md:w-60 sm:w-50 w-60 h-10 font-bold rounded-lg  bg-lightOrange text-white'
                    onClick={submitPreferredLanguage}>Continue
                </button>
                {checkPhoneExistsError && <span
                    className='text-sm text-center mt-0 text-red-700 rounded-lg dark:text-red-800'>{checkPhoneExistsError}</span>}
            </div>
        </>
    )
}

export default withPreferredLanguageSelection(AdminPreferredLanguageSelection)