import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import sjCircle from '../../assets/images/loginBackground/sj-circle.png'
import {createUserAccount, SignupRequest} from '../../store/actions/account.action'
import { StateParams } from '../../store/reducers'
import {MixpanelContext} from "../../analytics/Mixpanel";

const ReasonForVisit = () => {
    const analytics = React.useContext(MixpanelContext);
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const { t } = useTranslation();
    const { jwt, id, slug, createAccountError } = useSelector((state: StateParams) => state.account)
    const {emrId, firstName, lastName, phoneNumber, password, dob, hasAcceptedTerms, isLegalGuardian, isOneAmongMultipleChildren,
        hasPrematureBirth, zipCode, guardianEmail, guardianFirstName, guardianLastName, gender, isPatientCapable,
        guardianPhoneNumber, relationshipWithPatient, canShareEmergencyContact, languagePreference} = useSelector((state: StateParams)=> state.signup)
    const [data, setData] = useState({
        reasonForVisit: ""
    })

    const onChangeValue = (e: any) => {
        setData({ ...data, reasonForVisit: e.target.value })
    }

    const handleSubmit = (e: any) => {
        e.preventDefault()
        let payload: SignupRequest = {
            firstName: firstName,
            lastName: lastName,
            phoneNumber: phoneNumber,
            password: password,
            dob: dob,
            hasAcceptedTerms: hasAcceptedTerms,
            isLegalGuardian: isLegalGuardian,
            isOneAmongMultipleChildren: isOneAmongMultipleChildren,
            hasPrematureBirth: hasPrematureBirth,
            zipCode: zipCode,
            reasonForVisit: data.reasonForVisit,
            guardianFirstName: guardianFirstName,
            guardianLastName: guardianLastName,
            guardianEmail: guardianEmail,
            gender: gender,
            isPatientCapable: isPatientCapable,
            guardianPhoneNumber,
            relationshipWithPatient,
            canShareEmergencyContact,
            preferredLanguage: languagePreference
        };
        if(emrId) {
            payload = {...payload, emrId: emrId }
        }
        dispatch(createUserAccount(payload))
    }

    useEffect(() => {
        if (jwt && analytics) {
            const analyticsConfig = {id: id, slug: slug}
            analytics.track("patient_creation", analyticsConfig)
            navigate(`/patients/${id}`)
        }
    }, [jwt])

    return (
        <div className='h-screen mx-auto px-3 bg-sky-light-lg bg-no-repeat bg-cover'>
            <div className='w-full h-32 flex items-center justify-center'>
                <img src={sjCircle} className='h-[200px] mt-10' />
            </div>
            <p data-test="visit-title" className='text-lg font-bold mt-10'>{t('reasonForVisitTitle')}</p>
            <div className='mt-5'>
                <form onSubmit={handleSubmit}>
                    <div className='flex items-center bg-white m-2 h-12 rounded-md p-2'>
                        <input id='sickVisit' required type="radio" name='answer' value='Sick Visit' onChange={onChangeValue} className='h-4 w-4 mr-2 text-orange focus:ring-0' />
                        <label htmlFor={'sickVisit'} className='text-sm w-full'>{t('sickVisit')}</label>
                    </div>
                    <div className='flex items-center bg-white m-2 h-12 rounded-md p-2'>
                        <input id='wellCheck' required type="radio" name='answer' value='Well Check/Annual Visit' onChange={onChangeValue} className='h-4 w-4 mr-2 text-orange focus:ring-0' />
                        <label htmlFor={'wellCheck'} className='text-sm w-full'>{t('annualVisit')}</label>
                    </div>
                    <button data-test="visit-submit" className='bg-orange text-white font-bold p-2 rounded-xl w-full mt-20' type='submit' value="Submit">
                    {t('proceedButton')}
                    </button>
                  {createAccountError && <span className='text-sm text-center text-red-700 rounded-lg dark:text-red-800'>{createAccountError}</span>}

                </form>
            </div>
        </div>
    )
}

export default ReasonForVisit
