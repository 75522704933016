import axiosRetry from "axios-retry";
import axios from "axios";
console.log("API: ", process.env.REACT_APP_API_URL)
const apiHttpClient = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    headers: {
        "Content-type": "application/json",
        "x-api-key": process.env.REACT_APP_API_KEY || "need_to_set",
        "x-client-id": process.env.REACT_APP_CLIENT_ID || "postman"
    }
});
axiosRetry(apiHttpClient, { retries: 5, retryDelay: axiosRetry.exponentialDelay });

export {apiHttpClient}
