export const CALL_IN_PROGRESS = "CALL_IN_PROGRESS"
export const CALL_FINISHED = "CALL_FINISHED"

export const callInProgress = (event?: string) => {
    return {type: CALL_IN_PROGRESS, event}
}

export const callFinished = (event?: string) => {
    return {type: CALL_FINISHED, event}
}
