import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import {TRANSLATIONS_EN} from "./translations/english";
import {TRANSLATIONS_ES} from "./translations/spanish";
import { LanguageCodes } from './models';

i18n
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        debug: true,
        fallbackLng: LanguageCodes.EN_US,
        interpolation: {
            escapeValue: false, // not needed for react as it escapes by default
        },
        resources: {
            en_us: {
                translation: TRANSLATIONS_EN
            },
            es: {
                translation: TRANSLATIONS_ES
            }
        }
    });

i18n.changeLanguage(LanguageCodes.EN_US);