import { useAsyncDebounce } from 'react-table'
import React, { useEffect } from "react";  // new
import { MagnifyingGlassIcon } from '@heroicons/react/20/solid'
import { SearchButton } from './Buttons';
import { useLocation, useNavigate } from 'react-router-dom';

interface TableFilterProps {
    setTableFilter: Function
    totalRows: number
    currentPageHandler: Function
}

interface LocationState {
    sidebar: boolean
}

const TableFilterPagination: React.FC<TableFilterProps> = ({ setTableFilter, totalRows, currentPageHandler }) => {
    const location = useLocation()
    const [value, setValue] = React.useState('')

    useEffect(() => {
        const locationState: LocationState | null = location.state as LocationState
        if (locationState?.sidebar) {
            setValue('')
            setTableFilter('')
        }
    }, [location.state])

    const search = useAsyncDebounce(v => {
        setTableFilter(value || '')
        currentPageHandler(1)
    }, 200)

    const handleKeyPress = (e: any) => {
        if (e.key === 'Enter' || e.which === 13) {
            setTableFilter(value || '')
            currentPageHandler(1)
        }
    }
    return (
        <div>
            Search:{' '}
            <input
                type={"text"}
                className={"rounded-full focus:border-orange-300 focus:ring focus:ring-orange-200 focus:ring-opacity-50"}
                value={value || ""}
                onChange={e => {
                    setValue(e.target.value?.trim());
                }}
                onKeyPress={handleKeyPress}
                placeholder={`${totalRows} records...`}
            />
            <SearchButton className="rounded-full ml-1 top-1 focus:border-orange-300 focus:ring focus:ring-orange-200 focus:ring-opacity-50"
                onClick={search} title="Search here by Patient Name, ZIP Code and Phone number">
                <span className="sr-only">Search</span>
                <MagnifyingGlassIcon className="h-5 w-5" aria-hidden="true" />
            </SearchButton>
        </div>
    )
}

export default TableFilterPagination