import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import sjCircle from '../../assets/images/loginBackground/sj-circle.png'
import { AgeGroup } from '../../models'
import { setGuardianEmail, setGuardianFirstName, setGuardianLastName } from '../../store/actions/signupData.action'
import { StateParams } from '../../store/reducers'

export const RenderGuardianFirstName = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { t } = useTranslation();
  const { guardianFirstName } = useSelector((state: StateParams) => state.signup)

  const onChangeValue = (e: any) => {
    e.preventDefault()
    if (e.target.id) {
      dispatch(setGuardianFirstName({
        guardianFirstName: e.target.value
      }))
    }
  }

  const handleSubmit = (e: any) => {
    e.preventDefault()
    navigate('/legalGuardian/lastName')
  }

  return (
    <div className='h-screen max-w-[1240px] mx-auto px-3 bg-sky-light-lg bg-no-repeat bg-cover'>
      <div className='w-full h-32 flex items-center justify-center'>
        <img src={sjCircle} className='h-[200px] mt-10' />
      </div>
      <p data-test="lg-title" className='text-lg font-bold mt-10'>{t('legalGuardianTitle')}</p>
      <div className='mt-10'>
        <form onSubmit={handleSubmit}>
          <div>
            <label className='text-sm'>{t('guardianFirstName')}</label>
            <input data-test="lg-first-name" id='guardianFirstName' type='text' required onChange={(e) => onChangeValue(e)} value={guardianFirstName} className='w-full mt-4 rounded-md h-8 border-gray-300 focus:outline-none focus:border-orange focus:ring-1 focus:ring-orange' />
          </div>
          <button data-test="signup-proceed" className='bg-orange text-white font-bold p-2 rounded-xl w-full mt-20' type='submit' value="Submit">
            {t('proceedButton')}
          </button>
        </form>
      </div>
    </div>
  )
}

export const RenderGuardianLastName = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { t } = useTranslation();
  const { guardianLastName } = useSelector((state: StateParams) => state.signup)

  const onChangeValue = (e: any) => {
    e.preventDefault()
    if (e.target.id) {
      dispatch(setGuardianLastName({
        guardianLastName: e.target.value
      }))
    }
  }

  const handleSubmit = (e: any) => {
    e.preventDefault()
    navigate('/legalGuardian/email')
  }

  return (
    <div className='h-screen max-w-[1240px] mx-auto px-3 bg-sky-light-lg bg-no-repeat bg-cover'>
      <div className='w-full h-32 flex items-center justify-center'>
        <img src={sjCircle} className='h-[200px] mt-10' />
      </div>
      <p data-test="lg-title" className='text-lg font-bold mt-10'>{t('legalGuardianTitle')}</p>
      <div className='mt-10'>
        <form onSubmit={handleSubmit}>
          <div>
            <label className='text-sm'>{t('guardianLastName')}</label>
            <input data-test="lg-last-name" id='guardianLastName' type='text' required onChange={(e) => onChangeValue(e)} value={guardianLastName} className='w-full mt-4 rounded-md h-8 border-gray-300 focus:outline-none focus:border-orange focus:ring-1 focus:ring-orange' />
          </div>
          <button data-test="signup-proceed" className='bg-orange text-white font-bold p-2 rounded-xl w-full mt-20' type='submit' value="Submit">
            {t('proceedButton')}
          </button>
        </form>
      </div>
    </div>
  )
}

export const RenderGuardianEmail = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { t } = useTranslation();
  const { guardianEmail, isLegalGuardian, ageGroup } = useSelector((state: StateParams) => state.signup)

  const onChangeValue = (e: any) => {
    e.preventDefault()
    if (e.target.id) {
      dispatch(setGuardianEmail({
        guardianEmail: e.target.value
      }))
    }
  }

  const handleSubmit = (e: any) => {
    if (!isLegalGuardian && ageGroup === AgeGroup.BELOW_TWELVE) navigate('/belowTwelveNote')
    else if (isLegalGuardian && ageGroup === AgeGroup.BELOW_TWELVE) navigate('/reasonForVisit')
    else if (!isLegalGuardian && ageGroup === AgeGroup.TWELVE_TO_EIGHTEEN) navigate('/reasonForVisit')
    else if (isLegalGuardian && ageGroup === AgeGroup.TWELVE_TO_EIGHTEEN) navigate('/twelveToEighteenNote')
  }

  return (
    <div className='h-screen max-w-[1240px] mx-auto px-3 bg-sky-light-lg bg-no-repeat bg-cover'>
      <div className='w-full h-32 flex items-center justify-center'>
        <img src={sjCircle} className='h-[200px] mt-10' />
      </div>
      <p data-test="lg-title" className='text-lg font-bold mt-10'>{t('legalGuardianTitle')}</p>
      <div className='mt-10'>
        <form onSubmit={handleSubmit}>
          <div>
            <label className='text-sm'>{t('guardianEmail')}</label>
            <input data-test="lg-email" id='guardianEmail' type='text' required onChange={(e) => onChangeValue(e)} value={guardianEmail} className='w-full mt-4 rounded-md h-8 border-gray-300 focus:outline-none focus:border-orange focus:ring-1 focus:ring-orange' />
          </div>
          <button data-test="signup-proceed" className='bg-orange text-white font-bold p-2 rounded-xl w-full mt-20' type='submit' value="Submit">
            {t('proceedButton')}
          </button>
        </form>
      </div>
    </div>
  )
}
