import React, { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { patientScreenerStart } from "../../store/actions/account.action";
import { StateParams } from "../../store/reducers";
import {MixpanelContext} from "../../analytics/Mixpanel";

interface Value {
    hasPendingOrElapsedScreeners: boolean
    isEligibleForScreeningBasedOnStatus: boolean
    userId: string
    dob: Date
}

export const PatientScreenerStatusPill: React.FC<{ value: Value }> = ({ value }) => {
    const analytics = React.useContext(MixpanelContext);
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const { userScreenerId, provider } = useSelector((state: StateParams) => state.account)
    const [patientDob, setPatientDob] = useState<Date>()

    const goToDetails = useCallback(() => {
        setPatientDob(value.dob)
        analytics?.identify(value.userId)
        analytics?.track("start_admin_screening", {provider: provider?.slug, patientId: value.userId})
        dispatch(patientScreenerStart({ userId: value.userId, practiceType: provider?.practiceType }))
    }, [value])

    useEffect(() => {
        if (userScreenerId && patientDob) {
            navigate('/adminLanguagePreference', {state: {dob: patientDob}})
        }
    }, [userScreenerId])

    return (
        value.hasPendingOrElapsedScreeners && value.isEligibleForScreeningBasedOnStatus ?
            <button className="items-center px-2 py-2 border text-sm font-medium rounded-md bg-orange first-letter:rounded-l-md border-white text-white"
                    onClick={goToDetails} >
                Start Screening
            </button>
            : null
    );
}
