export const TRANSLATIONS_EN = {
    signupTitle: "Signup",
    signupHeader: "Please enter the following information to create an account.",
    mobileNumber: "Mobile Number",
    patientFirstname: "Patient's First Name",
    patientLastName: "Patient's Last Name",
    patientDob: "Patient's Date of Birth",
    patientGender: "Patient's Gender",
    male: "Male",
    female: "Female",
    zipCode: "Zip code",
    password: "Password",
    whoIsFillingForm: "Who is filling this form?",
    legalGuardian: "Caregiver",
    patient: "Patient",
    proceedButton: "Proceed",
    belowTwelveNote: "Given your age, we need your parent's input. Please handover the device to your parent or ask them to signup.",
    parent: "Yes, I am the Parent",
    notParent: "No, I am not the Parent",
    twelveToEighteenNote: "Given the patient's age, we need their input. Please handover the device to the patient for signup if they are capable answering the survey.",
    imPatient: "Yes, I am the Patient",
    notPatient: "No, I am not the Patient",
    patientIncapable: "The patient is not capable",
    legalGuardianTitle: "Enter your details",
    guardianFirstName: "Caregiver's First Name",
    guardianLastName: "Caregiver's Last Name",
    guardianEmail: "Email Address",
    emergencyContactTitle: "Enter your emergency contact details",
    emergencyFirstName: "First Name",
    emergencyLastName: "Last Name",
    emergencyPhoneNumber: "Phone number",
    relationType: "Relationship Type",
    canShareDetail: "In case of emergency, can we share your details with the above contact person",
    yes: "Yes",
    no: "No",
    reasonForVisitTitle: "Reason for the Visit",
    sickVisit: "Sick Visit",
    annualVisit: "Well Check/Annual Visit",
    welcomeTitle: "Screener Introduction",
    welcomeSubTitle: "Please identify any symptoms by answering the upcoming questiones, it will take 8 to 10 minutes of your time.",
    getStartedButton: "Get Started",
    adhdNote: "The following survey needs to be completed by your legal guardian or parent - please handover the device.",
    handoverButton: "Handover",
    loginTitle: "Login",
    loginSubTitle: "Your information will remain secure and confidential.",
    forgotPassword: "Forgot Password?",
    forgotPasswordSubTitle: "Please enter the phone number you use to sign in to Small Joys",
    backToLogin: "Back to Login",
    resetPassword: "Reset Password",
    resetPasswordSubTitle: "Please create a new password, your password should have at least 8 characters",
    enterCodeTitle: "Please enter the OTP sent to",
    change: "Change",
    verify: "Verify",
    notReceivedCode: "Not received your code?",
    resendCode: "Resend code",
    completedScreener: "You have completed a screener",
    nextScreener: "Go To Next Screener",
    thankYou: "Thank You!",
    completedQuestionnaires: "You have completed all the questionnaires.",
    handover: "Please handover the device to the admin",
    suicidalTitle: "You need to speak with someone today",
    suicidalSubTitle: "If you're thinking about hurting yourself, are worried about a friend or loved one, or would like emotional support, call us and speak with us (we're 24/7 available)",
    call: "Call",
    officialWebsite: "Official Website",
    oneDrinkEquals: "One drink equals",
    ozBeer: 'oz. beer',
    ozWine: 'oz. wine',
    ozLiquor: 'oz. liquor',
    ozLiquorOneShot: 'one shot'
}