import * as React from "react";
import {useSelector} from "react-redux";
import {StateParams} from "../../store/reducers";
import {Navigate, useLocation} from 'react-router-dom';

export function RequireAuth(props: any) {
    const {jwt, provider} = useSelector((state: StateParams) => state.account);
    const location = useLocation()

    return jwt || provider?.jwt ? props.children : <Navigate to="/" state={{redirect: location.pathname}} replace />;
}