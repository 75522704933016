import React, {useCallback, useEffect, useState} from "react";
import {useNavigate } from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import sjCircle from "../../assets/images/loginBackground/sj-circle.png";
import {clearErrorMessages, sendVerificationCode} from "../../store/actions/account.action";
import {checkIfPhoneNumberExists} from "../../store/actions/practice.action";
import {StateParams} from "../../store/reducers";
import EnterCode from "./EnterCode";
import {StackNames} from '../../models'
import PhoneInput from 'react-phone-number-input'
import { useTranslation } from "react-i18next";

const ForgotPassword: React.FC = () => {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const { t } = useTranslation();
    const [requestingPhoneNumber, setRequestingPhoneNumber] = useState<string>()
    const {accountExistsWithPhoneNumber, phoneNumber, checkPhoneExistsError} = useSelector((state: StateParams) => state.practice)
    const { sendCodeError, isSentSuccessfully, practiceId } = useSelector((state: StateParams) => state.account)
    const [codeSent, setCodeSent] = useState(false)

    useEffect(() => {
        dispatch(clearErrorMessages())
    }, [])
    
    const submitPhoneNumber = useCallback(() => {
        if(requestingPhoneNumber) {
            setCodeSent(true)
            dispatch(checkIfPhoneNumberExists(requestingPhoneNumber, practiceId as string))
        }
    }, [requestingPhoneNumber])

    useEffect(() => {
        if (phoneNumber === requestingPhoneNumber && accountExistsWithPhoneNumber === true && !checkPhoneExistsError) {
            if (phoneNumber) {
                dispatch(sendVerificationCode(phoneNumber))
            }
        }
    }, [phoneNumber, accountExistsWithPhoneNumber, checkPhoneExistsError])

    return (<>
        {(phoneNumber === requestingPhoneNumber && accountExistsWithPhoneNumber === true && codeSent
            ) ? 
                <EnterCode phoneNumber={requestingPhoneNumber} stackName={StackNames.FORGOT_PASSWORD}/> :
                    <div className='h-screen mx-auto px-3 bg-sky-light-lg bg-no-repeat bg-cover'>
                        <div className='w-full h-32 flex items-center justify-center'>
                            <img src={sjCircle} className='h-[200px] mt-10' />
                        </div>
                        <div className='w-full h-32 mt-10 flex flex-col items-center justify-center'>
                            <p className='text-lg font-bold'>{t('forgotPassword')}</p>
                            <span className='text-8xs'>{t('forgotPasswordSubTitle')}</span>
                            <PhoneInput required className='mt-5'type={"text"} name={"phoneNumber"} 
                                placeholder={t('emergencyPhoneNumber')}
                                defaultCountry="US"
                                onChange={ setRequestingPhoneNumber} />
                        </div>
                        <div className='w-full h-32 flex flex-col items-center justify-center'>
                            <button className='w-64 h-10 font-bold rounded-lg  bg-lightOrange text-white' onClick={submitPhoneNumber}>{t('proceedButton')}</button>
                            {checkPhoneExistsError && <span className='text-sm text-center mt-0 text-red-700 rounded-lg dark:text-red-800'>{checkPhoneExistsError}</span>}
                            {(sendCodeError && isSentSuccessfully === false) && <span className='text-sm text-red-700 rounded-lg dark:text-red-800'>{sendCodeError}</span>}
                            {(phoneNumber === requestingPhoneNumber && accountExistsWithPhoneNumber === false ) && <span className='text-sm text-red-700 rounded-lg dark:text-red-800'>Account does not exist.</span>}
                            <div>
                                <span className='text-8xs font-bold float-right	pt-1 text-hyperLink hover:text-blue-800 hover:cursor-pointer visited:text-purple-600'
                                    onClick={() => { navigate('/login') }}>{t('backToLogin')}
                                </span>
                            </div>
                        </div>
                    </div>
            }
    </>)
}

export default ForgotPassword