import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import sjCircle from '../../assets/images/loginBackground/sj-circle.png'
import { setEmergencyCanShare, setEmergencyEmail, setEmergencyFirstName, setEmergencyLastName, setEmergencyPhoneNumber, setEmergencyRelationType } from '../../store/actions/signupData.action'
import { StateParams } from '../../store/reducers'

export const RenderEmergencyFirstName = () => {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const { t } = useTranslation();
    const { guardianFirstName } = useSelector((state: StateParams) => state.signup)

    const onChangeValue = (e: any) => {
        e.preventDefault()
        if (e.target.id) {
            dispatch(setEmergencyFirstName({
                guardianFirstName: e.target.value
            }))
        }
    }

    const handleSubmit = (e: any) => {
        e.preventDefault()
        navigate('/emergencyContact/lastName')
    }

    return (
        <div className='h-screen max-w-[1240px] mx-auto px-3 bg-sky-light-lg bg-no-repeat bg-cover'>
            <div className='w-full h-32 flex items-center justify-center'>
                <img src={sjCircle} className='h-[200px] mt-10' />
            </div>
            <p data-test="emgergency-title" className='text-lg font-bold mt-10'>{t('emergencyContactTitle')}</p>
            <div className='mt-10'>
                <form onSubmit={handleSubmit}>
                    <div>
                        <label className='text-sm'>{t('emergencyFirstName')}</label>
                        <input data-test="emgergency-first-name" id='emergencyFirstName' type='text' required onChange={(e) => onChangeValue(e)} value={guardianFirstName} className='w-full mt-4 rounded-md h-8 border-gray-300 focus:outline-none focus:border-orange focus:ring-1 focus:ring-orange' />
                    </div>
                    <button data-test="signup-proceed" className='bg-orange text-white font-bold p-2 rounded-xl w-full mt-20' type='submit' value="Submit">
                        {t('proceedButton')}
                    </button>
                </form>
            </div>
        </div>
    )
}

export const RenderEmergencyLastName = () => {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const { t } = useTranslation();
    const { guardianLastName } = useSelector((state: StateParams) => state.signup)

    const onChangeValue = (e: any) => {
        e.preventDefault()
        if (e.target.id) {
            dispatch(setEmergencyLastName({
                guardianLastName: e.target.value
            }))
        }
    }

    const handleSubmit = (e: any) => {
        e.preventDefault()
        navigate('/emergencyContact/email')
    }

    return (
        <div className='h-screen max-w-[1240px] mx-auto px-3 bg-sky-light-lg bg-no-repeat bg-cover'>
            <div className='w-full h-32 flex items-center justify-center'>
                <img src={sjCircle} className='h-[200px] mt-10' />
            </div>
            <p data-test="emgergency-title" className='text-lg font-bold mt-10'>{t('emergencyContactTitle')}</p>
            <div className='mt-10'>
                <form onSubmit={handleSubmit}>
                    <div>
                        <label className='text-sm'>{t('emergencyLastName')}</label>
                        <input data-test="emgergency-last-name" id='emergencyLastName' type='text' required onChange={(e) => onChangeValue(e)} value={guardianLastName} className='w-full mt-4 rounded-md h-8 border-gray-300 focus:outline-none focus:border-orange focus:ring-1 focus:ring-orange' />
                    </div>
                    <button data-test="signup-proceed" className='bg-orange text-white font-bold p-2 rounded-xl w-full mt-20' type='submit' value="Submit">
                        {t('proceedButton')}
                    </button>
                </form>
            </div>
        </div>
    )
}

export const RenderEmergencyEmail = () => {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const { t } = useTranslation();
    const { guardianEmail } = useSelector((state: StateParams) => state.signup)

    const onChangeValue = (e: any) => {
        e.preventDefault()
        if (e.target.id) {
            dispatch(setEmergencyEmail({
                guardianEmail: e.target.value
            }))
        }
    }

    const handleSubmit = (e: any) => {
        e.preventDefault()
        navigate('/emergencyContact/phoneNumber')
    }

    return (
        <div className='h-screen max-w-[1240px] mx-auto px-3 bg-sky-light-lg bg-no-repeat bg-cover'>
            <div className='w-full h-32 flex items-center justify-center'>
                <img src={sjCircle} className='h-[200px] mt-10' />
            </div>
            <p data-test="emgergency-title" className='text-lg font-bold mt-10'>{t('emergencyContactTitle')}</p>
            <div className='mt-10'>
                <form onSubmit={handleSubmit}>
                    <div>
                        <label className='text-sm'>{t('guardianEmail')}</label>
                        <input data-test="emgergency-email" id='emergencyEmail' type='text' required onChange={(e) => onChangeValue(e)} value={guardianEmail} className='w-full mt-4 rounded-md h-8 border-gray-300 focus:outline-none focus:border-orange focus:ring-1 focus:ring-orange' />
                    </div>
                    <button data-test="signup-proceed" className='bg-orange text-white font-bold p-2 rounded-xl w-full mt-20' type='submit' value="Submit">
                        {t('proceedButton')}
                    </button>
                </form>
            </div>
        </div>
    )
}

export const RenderEmergencyPhoneNumber = () => {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const { t } = useTranslation();
    const { guardianPhoneNumber } = useSelector((state: StateParams) => state.signup)

    const onChangeValue = (e: any) => {
        e.preventDefault()
        if (e.target.id) {
            dispatch(setEmergencyPhoneNumber({
                guardianPhoneNumber: e.target.value
            }))
        }
    }

    const handleSubmit = (e: any) => {
        e.preventDefault()
        navigate('/emergencyContact/relationType')
    }

    return (
        <div className='h-screen max-w-[1240px] mx-auto px-3 bg-sky-light-lg bg-no-repeat bg-cover'>
            <div className='w-full h-32 flex items-center justify-center'>
                <img src={sjCircle} className='h-[200px] mt-10' />
            </div>
            <p data-test="emgergency-title" className='text-lg font-bold mt-10'>{t('emergencyContactTitle')}</p>
            <div className='mt-10'>
                <form onSubmit={handleSubmit}>
                    <div>
                        <label className='text-sm'>{t('emergencyPhoneNumber')}</label>
                        <input data-test="emgergency-phonenumber" id='emergencyPhoneNumber' type='text' required onChange={(e) => onChangeValue(e)} value={guardianPhoneNumber} className='w-full mt-4 rounded-md h-8 border-gray-300 focus:outline-none focus:border-orange focus:ring-1 focus:ring-orange' />
                    </div>
                    <button data-test="signup-proceed" className='bg-orange text-white font-bold p-2 rounded-xl w-full mt-20' type='submit' value="Submit">
                        {t('proceedButton')}
                    </button>
                </form>
            </div>
        </div>
    )
}

export const RenderEmergencyRelationType = () => {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const { t } = useTranslation();
    const { relationshipWithPatient } = useSelector((state: StateParams) => state.signup)

    const onChangeValue = (e: any) => {
        e.preventDefault()
        if (e.target.id) {
            dispatch(setEmergencyRelationType({
                relationshipWithPatient: e.target.value
            }))
        }
    }

    const handleSubmit = (e: any) => {
        e.preventDefault()
        navigate('/emergencyContact/canShare')
    }

    return (
        <div className='h-screen max-w-[1240px] mx-auto px-3 bg-sky-light-lg bg-no-repeat bg-cover'>
            <div className='w-full h-32 flex items-center justify-center'>
                <img src={sjCircle} className='h-[200px] mt-10' />
            </div>
            <p data-test="emgergency-title" className='text-lg font-bold mt-10'>{t('emergencyContactTitle')}</p>
            <div className='mt-10'>
                <form onSubmit={handleSubmit}>
                    <div>
                        <label className='text-sm'>{t('relationType')}</label>
                        <input data-test="emgergency-relationship-type" id='relationshipType' type='text' required onChange={(e) => onChangeValue(e)} value={relationshipWithPatient} className='w-full mt-4 rounded-md h-8 border-gray-300 focus:outline-none focus:border-orange focus:ring-1 focus:ring-orange' />
                    </div>
                    <button data-test="signup-proceed" className='bg-orange text-white font-bold p-2 rounded-xl w-full mt-20' type='submit' value="Submit">
                        {t('proceedButton')}
                    </button>
                </form>
            </div>
        </div>
    )
}

export const RenderEmergencyCanShare = () => {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const { t } = useTranslation();

    const canShareContact = (selected: boolean) => {
        if (selected) {
            dispatch(setEmergencyCanShare({
                canShareEmergencyContact: selected
            }))
        }
    }

    const handleSubmit = (e: any) => {
        e.preventDefault()
        navigate('/reasonForVisit')
    }

    return (
        <div className='h-screen max-w-[1240px] mx-auto px-3 bg-sky-light-lg bg-no-repeat bg-cover'>
            <div className='w-full h-32 flex items-center justify-center'>
                <img src={sjCircle} className='h-[200px] mt-10' />
            </div>
            <p data-test="emgergency-title" className='text-lg font-bold mt-10'>{t('emergencyContactTitle')}</p>
            <div className='mt-10'>
                <form onSubmit={handleSubmit}>
                    <div className='mt-4'>
                        <p className='font-bold text-sm'>{t('canShareDetail')}</p>
                        <div className='flex mt-4'>
                            <div className='flex items-center justify-center bg-white h-12 w-20 rounded-md mr-4'>
                                <input id='yes' type="radio" required name='canShareContact' value={'true'} onChange={(e) => { canShareContact(JSON.parse(e.target.value)) }} className='h-3 w-3 mr-2 text-orange focus:ring-0' />
                                <label htmlFor={'yes'} className='text-sm'>{t('yes')}</label>
                            </div>
                            <div className='flex items-center justify-center bg-white h-12 w-20 rounded-md'>
                                <input id='no' type="radio" required name='canShareContact' value={'false'} onChange={(e) => { canShareContact(JSON.parse(e.target.value)) }} className='h-3 w-3 mr-2 text-orange focus:ring-0' />
                                <label htmlFor={'no'} className='text-sm'>{t('no')}</label>
                            </div>
                        </div>
                    </div>
                    <button data-test="signup-proceed" className='bg-orange text-white font-bold p-2 rounded-xl w-full mt-20' type='submit' value="Submit">
                        {t('proceedButton')}
                    </button>
                </form>
            </div>
        </div>
    )
}
