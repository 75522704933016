import React, { ChangeEvent, useCallback, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import sjCircle from '../../assets/images/loginBackground/sj-circle.png'
import { AgeGroup, Gender, SignupDataNew } from '../../models'
import { clearAllData, setDob, setFirstName, setGender, setLastName, setPassword, setWhoIsFilling, setZipCode, signupData } from '../../store/actions/signupData.action'
import { useSelector } from "react-redux";
import { StateParams } from "../../store/reducers";
import { useTranslation } from 'react-i18next';
import { SelectDatepicker } from 'react-select-datepicker';
import moment from 'moment'

export const RenderFirstName = () => {
  const navigate = useNavigate()
  const { t } = useTranslation();
  const dispatch = useDispatch()
  const { practiceId } = useSelector((state: StateParams) => state.account)
  const { firstName } = useSelector((state: StateParams) => state.signup)

  useEffect(() => {
    if (practiceId === undefined) {
      navigate('/')
    }
  }, [practiceId])

  const onChangeValue = (e: any) => {
    e.preventDefault()
    if (e.target.id) {
      dispatch(setFirstName({
        firstName: e.target.value
      }))
    }
  }

  const handleSubmit = (e: any) => {
    e.preventDefault()
    navigate('/signup/lastName')
  }

  return (
    <div className='h-screen mx-auto px-3 bg-sky-light-lg bg-no-repeat bg-cover'>
      <div className='w-full h-32 flex items-center justify-center'>
        <img src={sjCircle} className='h-[200px] mt-10' />
      </div>
      <p data-test="signup-title" className='text-lg font-bold mt-10'>{t('signupTitle')}</p>
      <p className='text-xs'>{t('signupHeader')}</p>
      <div className='mt-10'>
        <form onSubmit={handleSubmit}>
          <div>
            <label className='text-sm'>{t('patientFirstname')}</label>
            <input data-test="signup-patient-first-name" id='firstName' type='text' required onChange={(e) => onChangeValue(e)} value={firstName} className='w-full mt-4 rounded-md h-8 border-gray-300 focus:outline-none focus:border-orange focus:ring-1 focus:ring-orange' />
          </div>
          <button data-test="signup-proceed" className='bg-orange text-white font-bold p-2 rounded-xl w-full mt-20' type='submit' value="Submit">
            {t('proceedButton')}
          </button>
        </form>

      </div>
    </div>
  )
}

export const RenderLastName = () => {
  const navigate = useNavigate()
  const { t } = useTranslation();
  const dispatch = useDispatch()
  const { practiceId } = useSelector((state: StateParams) => state.account)
  const { lastName } = useSelector((state: StateParams) => state.signup)

  useEffect(() => {
    if (practiceId === undefined) {
      navigate('/')
    }
  }, [practiceId])

  const onChangeValue = (e: any) => {
    e.preventDefault()
    if (e.target.id) {
      dispatch(setLastName({
        lastName: e.target.value
      }))
    }
  }

  const handleSubmit = (e: any) => {
    e.preventDefault()
    navigate('/signup/dob')
  }

  return (
    <div className='h-screen mx-auto px-3 bg-sky-light-lg bg-no-repeat bg-cover'>
      <div className='w-full h-32 flex items-center justify-center'>
        <img src={sjCircle} className='h-[200px] mt-10' />
      </div>
      <p data-test="signup-title" className='text-lg font-bold mt-10'>{t('signupTitle')}</p>
      <p className='text-xs'>{t('signupHeader')}</p>
      <div className='mt-10'>
        <form onSubmit={handleSubmit}>
          <div>
            <label className='text-sm'>{t('patientLastName')}</label>
            <input data-test="signup-patient-last-name" id='lastName' type='text' required onChange={(e) => onChangeValue(e)} value={lastName} className='w-full mt-4 rounded-md h-8 border-gray-300 focus:outline-none focus:border-orange focus:ring-1 focus:ring-orange' />
          </div>
          <button data-test="signup-proceed" className='bg-orange text-white font-bold p-2 rounded-xl w-full mt-20' type='submit' value="Submit">
            {t('proceedButton')}
          </button>
        </form>
      </div>
    </div>
  )
}

export const RenderDob = () => {
  const navigate = useNavigate()
  const { t } = useTranslation();
  const dispatch = useDispatch()
  const { practiceId } = useSelector((state: StateParams) => state.account)
  const dob = useSelector((state: StateParams) => state.signup.dob)
  const [value, setValue] = useState<Date | null>()

  useEffect(() => {
    console.log("ue", { dob, value})
    if (value) {
      const isoDate = moment(value).format("YYYY-MM-DDTHH:mm:ss.sss[Z]");
      const ageGroup = calculateAgeGroup(new Date(value))

      dispatch(setDob({ dob: isoDate, ageGroup: ageGroup }))
    }
  }, [value])

  useEffect(() => {
    if (practiceId === undefined) {
      navigate('/')
    }
  }, [practiceId])

  const calculateAgeGroup = (dob: Date): AgeGroup => {
    const age = Math.floor((new Date().getTime() - new Date(dob).getTime()) / 3.15576e+10)
    if (age < 12) return AgeGroup.BELOW_TWELVE
    if (age >= 12 && age < 18) return AgeGroup.TWELVE_TO_EIGHTEEN
    return AgeGroup.ABOVE_EIGHTEEN
  }

  const onDateChange = useCallback((date: Date | null) => {
    setValue(date)
  }, []);

  const handleSubmit = (e: any) => {
    if(value) {
      e.preventDefault()
      navigate('/signup/gender')
    }
  }

  return (
    <div className='h-screen mx-auto px-3 bg-sky-light-lg bg-no-repeat bg-cover'>
      <div className='w-full h-32 flex items-center justify-center'>
        <img src={sjCircle} className='h-[200px] mt-10' />
      </div>
      <p data-test="signup-title" className='text-lg font-bold mt-10'>{t('signupTitle')}</p>
      <p className='text-xs'>{t('signupHeader')}</p>
      <div className='mt-10'>
        <form onSubmit={handleSubmit}>
          <div>
            <label className='text-sm'>{t('patientDob')}</label>
            <SelectDatepicker
              selectedDate={value}
              onDateChange={onDateChange}
              className= 'mt-5 text-sm'
            />
          </div>
          <button data-test="signup-proceed" className='bg-orange text-white font-bold p-2 rounded-xl w-full mt-20' type='submit' value="Submit">
            {t('proceedButton')}
          </button>
        </form>
      </div>
    </div>
  )
}

export const RenderGender = () => {
  const navigate = useNavigate()
  const { t } = useTranslation();
  const dispatch = useDispatch()
  const { practiceId } = useSelector((state: StateParams) => state.account)

  useEffect(() => {
    if (practiceId === undefined) {
      navigate('/')
    }
  }, [practiceId])

  const onGenderSelection = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.value === Gender.MALE) {
      dispatch(setGender({ gender: Gender.MALE }))
    } else if (e.target.value === Gender.FEMALE) {
      dispatch(setGender({ gender: Gender.FEMALE }))
    }
  }

  const handleSubmit = (e: any) => {
    e.preventDefault()
    navigate('/signup/zipcode')
  }

  return (
    <div className='h-screen mx-auto px-3 bg-sky-light-lg bg-no-repeat bg-cover'>
      <div className='w-full h-32 flex items-center justify-center'>
        <img src={sjCircle} className='h-[200px] mt-10' />
      </div>
      <p data-test="signup-title" className='text-lg font-bold mt-10'>{t('signupTitle')}</p>
      <p className='text-xs'>{t('signupHeader')}</p>
      <div className='mt-10'>
        <form onSubmit={handleSubmit}>
          <label className='text-sm'>{t('patientGender')}</label>
          <div className='flex mt-4'>
            <div className='flex items-center justify-center bg-white h-12 w-24 rounded-md mr-4'>
              <input id='male' type="radio" required name='gender' value={'male'} onChange={onGenderSelection} className='h-3 w-3 mr-2 text-orange focus:ring-0' />
              <label htmlFor={'male'} className='text-sm'>{t('male')}</label>
            </div>
            <div className='flex items-center justify-center bg-white h-12 w-24 rounded-md'>
              <input id='female' type="radio" required name='gender' value={'female'} onChange={onGenderSelection} className='h-3 w-3 mr-2 text-orange focus:ring-0' />
              <label htmlFor={'female'} className='text-sm'>{t('female')}</label>
            </div>
          </div>
          <button data-test="signup-proceed" className='bg-orange text-white font-bold p-2 rounded-xl w-full mt-20' type='submit' value="Submit">
            {t('proceedButton')}
          </button>
        </form>
      </div>
    </div>
  )
}

export const RenderZipcode = () => {
  const navigate = useNavigate()
  const { t } = useTranslation();
  const dispatch = useDispatch()
  const { practiceId } = useSelector((state: StateParams) => state.account)
  const { zipCode } = useSelector((state: StateParams) => state.signup)

  useEffect(() => {
    if (practiceId === undefined) {
      navigate('/')
    }
  }, [practiceId])

  const onChangeValue = (e: any) => {
    e.preventDefault()
    if (e.target.id) {
      dispatch(setZipCode({
        zipCode: e.target.value
      }))
    }
  }

  const handleSubmit = (e: any) => {
    e.preventDefault()
    navigate('/signup/password')
  }

  return (
    <div className='h-screen mx-auto px-3 bg-sky-light-lg bg-no-repeat bg-cover'>
      <div className='w-full h-32 flex items-center justify-center'>
        <img src={sjCircle} className='h-[200px] mt-10' />
      </div>
      <p data-test="signup-title" className='text-lg font-bold mt-10'>{t('signupTitle')}</p>
      <p className='text-xs'>{t('signupHeader')}</p>
      <div className='mt-10'>
        <form onSubmit={handleSubmit}>
          <div>
            <label className='text-sm'>{t('zipCode')}</label>
            <input data-test="signup-patient-zipcode" id='zipCode' type='number' required onChange={(e) => onChangeValue(e)} value={zipCode} className='w-full mt-4 rounded-md h-8 border-gray-300 focus:outline-none focus:border-orange focus:ring-1 focus:ring-orange' />
          </div>
          <button data-test="signup-proceed" className='bg-orange text-white font-bold p-2 rounded-xl w-full mt-20' type='submit' value="Submit">
            {t('proceedButton')}
          </button>
        </form>
      </div>
    </div>
  )
}

export const RenderPassword = () => {
  const navigate = useNavigate()
  const { t } = useTranslation();
  const dispatch = useDispatch()
  const { practiceId } = useSelector((state: StateParams) => state.account)
  const { password } = useSelector((state: StateParams) => state.signup)

  useEffect(() => {
    if (practiceId === undefined) {
      navigate('/')
    }
  }, [practiceId])

  const onChangeValue = (e: any) => {
    e.preventDefault()
    if (e.target.id) {
      dispatch(setPassword({
        password: e.target.value
      }))
    }
  }

  const handleSubmit = (e: any) => {
    e.preventDefault()
    navigate('/signup/whoIsFilling')
  }

  return (
    <div className='h-screen mx-auto px-3 bg-sky-light-lg bg-no-repeat bg-cover'>
      <div className='w-full h-32 flex items-center justify-center'>
        <img src={sjCircle} className='h-[200px] mt-10' />
      </div>
      <p data-test="signup-title" className='text-lg font-bold mt-10'>{t('signupTitle')}</p>
      <p className='text-xs'>{t('signupHeader')}</p>
      <div className='mt-10'>
        <form onSubmit={handleSubmit}>
          <div>
            <label className='text-sm'>{t('password')}</label>
            <input data-test="signup-password" id='password' type='password' required onChange={(e) => onChangeValue(e)} value={password} className='w-full mt-4 rounded-md h-8 border-gray-300 focus:outline-none focus:border-orange focus:ring-1 focus:ring-orange' />
          </div>
          <button data-test="signup-proceed" className='bg-orange text-white font-bold p-2 rounded-xl w-full mt-20' type='submit' value="Submit">
            {t('proceedButton')}
          </button>
        </form>
      </div>
    </div>
  )
}

export const RenderWhoIsFilling = () => {
  const navigate = useNavigate()
  const { t } = useTranslation();
  const dispatch = useDispatch()
  const { practiceId, languagePreference } = useSelector((state: StateParams) => state.account)
  const { ageGroup, isLegalGuardian } = useSelector((state: StateParams) => state.signup)
  const { phoneNumber } = useSelector((state: StateParams) => state.practice)
  const [data, setData] = useState<SignupDataNew>({
    phoneNumber: "",
    isLegalGuardian: false,
    hasAcceptedTerms: false,
    isOneAmongMultipleChildren: false,
    hasPrematureBirth: false,
    preferredLanguage: undefined
  })

  useEffect(() => {
    if (practiceId === undefined) {
      navigate('/')
    }
  }, [practiceId])

  const onClickRadio = (e: any) => {
    if (phoneNumber) {
      return e.target.value === 'legalGuardian' ? dispatch(setWhoIsFilling({ ...data, isLegalGuardian: true, preferredLanguage: languagePreference, phoneNumber: phoneNumber })) : dispatch(setWhoIsFilling({ ...data, isLegalGuardian: false, preferredLanguage: languagePreference, phoneNumber: phoneNumber }))
    }
  }

  const handleSubmit = (e: any) => {
    e.preventDefault()
    if (ageGroup === AgeGroup.BELOW_TWELVE || ageGroup === AgeGroup.TWELVE_TO_EIGHTEEN) navigate('/legalGuardian/firstName')
    else if (ageGroup === AgeGroup.ABOVE_EIGHTEEN && !isLegalGuardian) navigate('/emergencyContact/firstName')
    else if (ageGroup === AgeGroup.ABOVE_EIGHTEEN && isLegalGuardian) navigate('/twelveToEighteenNote')
  }

  return (
    <div className='h-screen mx-auto px-3 bg-sky-light-lg bg-no-repeat bg-cover'>
      <div className='w-full h-32 flex items-center justify-center'>
        <img src={sjCircle} className='h-[200px] mt-10' />
      </div>
      <p data-test="signup-title" className='text-lg font-bold mt-10'>{t('signupTitle')}</p>
      <p className='text-xs'>{t('signupHeader')}</p>
      <div className='mt-10'>
        <form onSubmit={handleSubmit}>
          <label className='text-sm'>{t('whoIsFillingForm')}</label>
          <div className='flex mt-4'>
            <div className='flex items-center justify-center bg-white h-12 w-40 rounded-md mr-4'>
              <input id='isLegalGuardian' type="radio" required name='userType' value={'legalGuardian'} onChange={onClickRadio} className='h-3 w-3 mr-2 text-orange focus:ring-0' />
              <label htmlFor={'isLegalGuardian'} className='text-sm'>{t('legalGuardian')}</label>
            </div>
            <div className='flex items-center justify-center bg-white h-12 w-40 rounded-md'>
              <input id='isPatient' type="radio" required name='userType' value={'patient'} onChange={onClickRadio} className='h-3 w-3 mr-2 text-orange focus:ring-0' />
              <label htmlFor={'isPatient'} className='text-sm'>{t('patient')}</label>
            </div>
          </div>
          <button data-test="signup-proceed" className='bg-orange text-white font-bold p-2 rounded-xl w-full mt-20' type='submit' value="Submit">
            {t('proceedButton')}
          </button>
        </form>
      </div>
    </div>
  )
}
