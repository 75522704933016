import React, { useCallback, useEffect, useState } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { clearScreenerQuestions, getQuestionDetails, saveUserScreenerAnswer, setNextQuestionLink } from '../../store/actions/screenerQuestions.action'
import { StateParams } from '../../store/reducers'
import { FaHome, FaPowerOff } from 'react-icons/fa'
import { clearUserId, logoutProvider, logoutUser, setNextUserScreenerId, setScreenerSlug } from '../../store/actions/account.action'
import { AnswerType, IScreenerAnswer, LanguageCodes, MULTI_CHOICE_QUESTION_SLUGS_METADATA, SCREENER_SLUGS, UserScreenerMultiSelect } from '../../models'
import {MixpanelContext} from "../../analytics/Mixpanel";
import { applyScreenerStyles, getScreenerConfigBySlug } from '../../assets/styles/config'
import Alcohol from './context/Alcohol'

const ScreenerQuestion = () => {
    const analytics = React.useContext(MixpanelContext);
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const params = useParams();
    const userScreenerId = params.userScreenerId
    const questionId = params.questionId
    const [topBackground, setTopBackground] = useState('bg-sun-sky-lg')
    const [backgroundColor, setBackgroundColor] = useState('bg-paleBackgroundYellow')
    const [bottomBackground, setBottomBackground] = useState('bg-birds-lg')
    const [bottomImageHeight, setBottomImageHeight] = useState('h-[8px]')
    const [progressDarkColor, setProgressDarkColor] = useState('bg-paleRed')
    const [progressLightColor, setProgressLightColor] = useState('bg-paleOrange')
    const [headerContentFontColor, setHeaderContentFontColor] = useState('text-black')
    const [questionContentFontColor, setQuestionContentFontColor] = useState('text-paleRed')
    const [contextComponent, setContextComponent] = useState<any>()
    const [multichoiceAnswerList, setMultichoiceAnswerList] = useState<Record<string, string | boolean>>({})
    const { currentQuestionDetails, nextQuestionLink, nextScreenerId, nextScreenerSlug, allScreenerQuestions, languagePreferenceOnAdminFlow } = useSelector((state: StateParams) => state.screener)
    const { userScreenerSlug, languagePreference, screenName, id, slug, provider } = useSelector((state: StateParams) => state.account)

    const isFieldSelected = (answerId: string): boolean => {
        if(currentQuestionDetails && currentQuestionDetails.userScreenerAnswers && currentQuestionDetails.userScreenerAnswers.length > 0) {
            const isAnswerSaved = !!currentQuestionDetails.userScreenerAnswers.find(answer => answer.answerId === answerId)
            return isAnswerSaved
        }
        return false
    }

    const handleMultiSelect = (answerId: string, response: string | boolean) => {
        const selection: Record<string, string | boolean> = {...multichoiceAnswerList}
        selection[answerId] = response
        setMultichoiceAnswerList(selection)
    }

    const handleMultiSelectSave = () => {
        if (!userScreenerId || !questionId) return
        const multiSelectedAnswers: UserScreenerMultiSelect[] = Object
            .entries(multichoiceAnswerList)
            .map(([answerId, response]) => ({ answerId, response }))
            .filter(response => response.response !== undefined);
        if(multiSelectedAnswers.length > 0) {
            dispatch(saveUserScreenerAnswer({
                screenerId: userScreenerId,
                questionId: questionId,
                answerId: multiSelectedAnswers[0].answerId,
                response: multiSelectedAnswers
            }))
        }
    }

    useEffect(() => {
        if(currentQuestionDetails && currentQuestionDetails.userScreenerAnswers && currentQuestionDetails.userScreenerAnswers.length > 0) {
            const selection: Record<string, string | boolean> = {}
            currentQuestionDetails.screenerAnswers.map(screenerAnswer => {
                if([AnswerType.CHECKBOX, AnswerType.TEXTBOX].includes(screenerAnswer.type)) {
                    const response = (currentQuestionDetails.userScreenerAnswers && currentQuestionDetails.userScreenerAnswers.find(answer => screenerAnswer.id === answer.answerId)?.additionalInfo)
                    selection[screenerAnswer.id] = response ? response : undefined
                }
            })
            setMultichoiceAnswerList(selection)
        }
    }, [currentQuestionDetails])

    useEffect(() => {
        if (!userScreenerId || !questionId) return
        dispatch(getQuestionDetails({
            screenerId: userScreenerId,
            questionId: questionId,
            preferredLanguage: languagePreference ? languagePreference : languagePreferenceOnAdminFlow
        }))
    }, [userScreenerId, questionId])

    const selectedAnswer = useCallback((e: any) => {
        if (!userScreenerId || !questionId) return
        const answerId = e.target.value
            dispatch(saveUserScreenerAnswer({
                screenerId: userScreenerId,
                questionId: questionId,
                answerId: answerId
            }))
    }, [userScreenerId, questionId])

    useEffect(() => {
        if (nextQuestionLink === null && nextScreenerId) {
            const analyticsConfig = { screener: userScreenerSlug, patientId: id, provider: provider?.slug };
            analytics?.track("screener_finished", analyticsConfig)
            dispatch(setNextUserScreenerId({
                nextUserScreenerId: nextScreenerId
            }))
            navigate('/screenerQuestion')
        } else if(nextQuestionLink === null && !nextScreenerId) {
            const analyticsConfig = { screener: userScreenerSlug, patientId: id, provider: provider?.slug };
            analytics?.track("screener_finished", analyticsConfig)
            const allScreenersFinished = { patientId: id, provider: provider?.slug };
            analytics?.track("all_screeners_finished", allScreenersFinished)
            navigate('/thankYou')
        } else if (nextQuestionLink) {
            navigate(nextQuestionLink)
        }
    }, [nextQuestionLink])

    const logout = () => {
        dispatch(logoutProvider())
        dispatch(clearScreenerQuestions())
        navigate(`/adminLogin`)
    }

    const home = () => {
        dispatch(clearUserId())
        navigate('/patients?page=1')
    }

    useEffect(()=>{
        if(currentQuestionDetails){
            const analyticsConfig = { screener: userScreenerSlug, patientId: id, provider: provider?.slug, question: currentQuestionDetails.slug };
            analytics?.track("screener_question", analyticsConfig)
            dispatch(setNextQuestionLink())
        }
    },[currentQuestionDetails])

    useEffect(() => {
        if (nextScreenerSlug) {
            dispatch(setScreenerSlug({
                nextScreenerSlug: nextScreenerSlug
            }))
        }
    }, [nextScreenerSlug])

    useEffect(() => {
        if (userScreenerSlug) {
            const config = getScreenerConfigBySlug(userScreenerSlug)
            if (config) {
                applyScreenerStyles(config, {
                    setTopBackground,
                    setBackgroundColor,
                    setBottomBackground,
                    setBottomImageHeight,
                    setProgressDarkColor,
                    setProgressLightColor,
                    setHeaderContentFontColor,
                    setQuestionContentFontColor,
                    setContextComponent
                })
            }
        }
    }, [userScreenerSlug])


    const sortAnswerByOrder = (first: IScreenerAnswer, second: IScreenerAnswer) => {
        if(first.order > second.order) return 1
        else return -1
    }

    const renderHeaderContentList = (headerContent: string) => {
        const question = headerContent.split('\u2022')[0]
        const list = headerContent.split('\u2022')
        return <div>
            <p className='text-sm'>{question}</p>
            <ul className='mt-2'>
                {list.map((item, index) => {
                    if(index !== 0) {
                        return <li className='list-disc text-sm ml-5'>{item}</li>
                    }
                })}
            </ul>
        </div>
    }
    return (
        <>
            <div className={`h-screen ${backgroundColor}  w-full`}>
                <div className={`w-full h-[217px] bg-cover bg-no-repeat ${topBackground}`}>
                    <div className={`w-full flex ${provider?.jwt ? 'justify-between' : 'justify-end'}`}>
                        {provider?.jwt ? <FaHome className='mt-2' onClick={home} /> : <></>}
                        <FaPowerOff data-tooltip-target="tooltip-bottom" data-tooltip-placement="bottom" className='m-2' onClick={logout} />
                    </div>
                </div>
                <div className={`flex items-center justify-center ${progressDarkColor} mx-32 mt-2 h-6 rounded-2xl p-2`}>
                    <p className='text-white text-xs'>{currentQuestionDetails && allScreenerQuestions ? `${currentQuestionDetails?.order}/${allScreenerQuestions?.length}` : null}</p>
                </div>
                {currentQuestionDetails && currentQuestionDetails.headerContent &&
                    <p className={`${headerContentFontColor} font-sans font-bold text-lg mt-4 px-2`}>
                        {currentQuestionDetails.headerContent.split('\u2022').length > 1 ?
                            renderHeaderContentList(currentQuestionDetails.headerContent)
                            : currentQuestionDetails.headerContent
                        }
                    </p>}
                <>{contextComponent && contextComponent }</>
                {userScreenerSlug && SCREENER_SLUGS.Alcohol === (userScreenerSlug) && <Alcohol/>}
                <p className={`${questionContentFontColor} font-sans font-bold text-lg mt-4 px-2`}>
                    {currentQuestionDetails && currentQuestionDetails.content}
                </p>
                <div className='overflow-auto h-[370px]'>
                {currentQuestionDetails &&
                    currentQuestionDetails
                    .screenerAnswers
                    .sort(sortAnswerByOrder)
                    .map((answer, i) => {
                    return (
                        <>
                            {
                                answer.type === AnswerType.RADIO &&
                                <div key={answer.id} className='flex justify-between items-center bg-white m-2 h-12 rounded-md p-2'>
                                    <label htmlFor={'screenerAnswer' + i} className='w-full'>{answer.content}</label>
                                    <input id={'screenerAnswer' + i} checked={isFieldSelected(answer.id)} type="radio" name='answer' value={answer.id} className='h-4 w-4 text-orange focus:ring-0' onChange={selectedAnswer} />
                                </div>
                            }
                            {
                                answer.type === AnswerType.CHECKBOX &&
                                <div key={answer.id} className='flex justify-between items-center bg-white m-2 h-12 rounded-md p-2'>
                                    <label className=''>
                                        <input type="checkbox" value={answer.id} checked={multichoiceAnswerList[answer.id] === true}
                                            className='text-orange w-5 h-5 rounded-s focus:ring-0'
                                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleMultiSelect(answer.id, event.target.checked)}/>
                                        <span className="pl-3">{answer.content}</span>
                                    </label>
                                </div>
                            }
                            {
                                answer.type === AnswerType.TEXTBOX &&
                                <div key={answer.id} className='bg-white m-2 h-15 rounded-md p-2'>
                                    <label className='block' htmlFor={answer.id}>{answer.content}</label>
                                        <input type='text' id={answer.id} onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleMultiSelect(answer.id, event.target.value)}
                                            value={multichoiceAnswerList[answer.id] ? multichoiceAnswerList[answer.id].toString() : ''}
                                            className='w-full block rounded-md h-8 border-gray-300 focus:outline-none focus:border-orange focus:ring-1 focus:ring-orange' />
                                </div>
                            }
                        </>
                    )
                    })}
                </div>
                {
                    currentQuestionDetails && MULTI_CHOICE_QUESTION_SLUGS_METADATA.includes(currentQuestionDetails.slug) &&
                    <div className='w-full h-32 flex flex-col items-center justify-center pb-16'>
                        <button data-test="practice-continue"
                            className='md:w-60 sm:w-50 w-60 h-10 font-bold rounded-lg  bg-lightOrange text-white'
                            onClick={handleMultiSelectSave}>Continue
                        </button>
                    </div>
                }
                <div className={`w-full ${bottomBackground} bg-cover absolute inset-x-0 bottom-0 ${bottomImageHeight}`} />
            </div>
        </>
    )
}

export default ScreenerQuestion
