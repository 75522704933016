import React from "react";
import {useGlobalFilter, usePagination, useTable} from "react-table";

interface TableProps {
  columns: any
  data: any
}

const TablePagination: React.FC<TableProps> = ({columns, data}) => {
  // Use the state and functions returned from useTable to build your UI
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    // @ts-ignore
    preGlobalFilteredRows,
    // @ts-ignore
    page,
    rows
  } = useTable({columns, data,}, useGlobalFilter, usePagination);

  return (
    <>
      <div className="mt-2 h-full overflow-auto flex flex-col">
        <div className="-my-2 lg:-mx-8">
          <div className="py-2 align-middle inline-block min-w-full lg:px-8">
            <div className="shadow border-b border-gray-200 sm:rounded-lg">
              <table {...getTableProps()} className="min-w-full divide-y divide-gray-200">
                <thead>
                {headerGroups.map((headerGroup: any) => (
                  <tr {...headerGroup.getHeaderGroupProps()}>
                    {headerGroup.headers.map((column: any) => (
                      <th {...column.getHeaderProps()}
                          className="px-4 py-3 sticky top-0 z-10 text-left text-sm font-bold text-black bg-white uppercase tracking-wider">
                        {column.render("Header")}
                      </th>
                    ))}
                  </tr>
                ))}
                </thead>
                <tbody {...getTableBodyProps()} className="bg-white divide-y divide-gray-200">
                {rows.map((row: any, i: number) => {
                  prepareRow(row);
                  return (
                    <tr {...row.getRowProps()} key={i}>
                      {row.cells.map((cell: any) => {
                        return <td {...cell.getCellProps()}
                                   className="w-1/3 px-4 py-4 break-words">{cell.render("Cell")}</td>;
                      })}
                    </tr>
                  );
                })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default TablePagination;