import React from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import {useNavigate} from 'react-router-dom'
import sjCircle from '../../assets/images/loginBackground/sj-circle.png'
import { AgeGroup } from '../../models'
import { setIsLegalGuardian, setIsPatientCapable } from '../../store/actions/signupData.action'
import { StateParams } from '../../store/reducers'

enum NoteHandler {
    IS_PATIENT,
    IS_NOT_PATIENT,
    IS_PATIENT_NOT_CAPABLE
}

const TwelveToEighteenNote: React.FC<any> = () => {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const { t } = useTranslation();
    const {ageGroup} = useSelector((state: StateParams)=> state.signup)


    const handleClick = (selected: string) => {
        if(Number(selected) === NoteHandler.IS_PATIENT) {
            dispatch(setIsLegalGuardian({ isLegalGuardian: false}))
            if(ageGroup === AgeGroup.ABOVE_EIGHTEEN) {
                setTimeout(() => navigate('/emergencyContact/firstName'), 250) 
            } else {
                setTimeout(() => navigate('/reasonForVisit'), 250)
            }
        } else if(Number(selected) === NoteHandler.IS_NOT_PATIENT) {
            setTimeout(() => navigate('/signup/firstName'), 250)
        } else if (Number(selected) === NoteHandler.IS_PATIENT_NOT_CAPABLE) {
            dispatch(setIsLegalGuardian({ isLegalGuardian: true }))
            dispatch(setIsPatientCapable({ isPatientCapable: false }))
            if(ageGroup === AgeGroup.ABOVE_EIGHTEEN) {
                setTimeout(() => navigate('/emergencyContact/firstName'), 250) 
            } else {
                setTimeout(() => navigate('/reasonForVisit'), 250)
            }
        }
    }
    
    return (
        <div className='h-screen px-3 bg-sky-light-lg bg-no-repeat bg-cover'>
            <div className='w-full h-32 flex items-center justify-center'>
                <img src={sjCircle} className='h-[200px] mt-10' alt="sjCircle"/>
            </div>
            <div className='mt-16'>
                <p data-test="note-below12to18-title" className='text-md font-bold text-center'>
                {t('twelveToEighteenNote')}
                </p>
            </div>
            <div className='mt-4 pr-10 pl-10'>
                <label className='flex items-center bg-[#FFEFD5] h-10 rounded-md pl-4'>
                    <input id='male' type="radio" required name='isPatient' value={NoteHandler.IS_PATIENT} onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleClick(event.target.value)}
                    className='h-4 w-4 text-orange focus:ring-0' />
                    <p className='text-md pl-10'>{t('imPatient')}</p>
                </label>
            </div>
            <div className='mt-4 pr-10 pl-10'>
                <label className='flex items-center bg-[#FFEFD5] h-10 rounded-md pl-4'>
                    <input id='male' type="radio" required name='isPatient' value={NoteHandler.IS_NOT_PATIENT} onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleClick(event.target.value)}
                    className='h-4 w-4 text-orange focus:ring-0' />
                    <p className='text-md pl-10'>{t('notPatient')}</p>
                </label>
            </div>
            <div className='mt-4 pr-10 pl-10'>
                <label className='flex items-center bg-[#FFEFD5] h-10 rounded-md pl-4'>
                    <input id='male' type="radio" required name='isPatient' value={NoteHandler.IS_PATIENT_NOT_CAPABLE} onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleClick(event.target.value)}
                    className='h-4 w-4 text-orange focus:ring-0' />
                    <p className='text-md pl-10'>{t('patientIncapable')}</p>
                </label>
            </div>
        </div>
    )
}

export default TwelveToEighteenNote