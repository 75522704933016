import {all, fork} from 'redux-saga/effects';
import accountSaga from './account.saga';
import screenerQuestionsSaga from "./screenerQuestions.saga";
import practiceSaga from "./practice.saga";

export function* rootSaga() {
    yield all([
        fork(practiceSaga),
        fork(accountSaga),
        fork(screenerQuestionsSaga)
    ])
}
