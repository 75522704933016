import React, {useCallback, useEffect, useState} from "react";
import {Link, useNavigate, useParams, useSearchParams} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import sjCircle from "../../assets/images/loginBackground/sj-circle.png";
import {
  clearErrorMessages,
  getPracticeId,
  logoutUser,
  sendVerificationCode,
  setPracticeId,
  setSlug
} from "../../store/actions/account.action";
import {checkIfPhoneNumberExists} from "../../store/actions/practice.action";
import {StateParams} from "../../store/reducers";
import EnterCode from "./EnterCode";
import {StackNames} from '../../models'
import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-number-input'

const PracticeEntry: React.FC = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const params = useParams();
  const [searchParams, setSearchParams] = useSearchParams();
  let id: string | null = ''
  let slug: string = ''
  const [practiceIdMissing, setPracticeIdMissing] = useState<true>()
  const [requestingPhoneNumber, setRequestingPhoneNumber] = useState<string>()
  const {
    accountExistsWithPhoneNumber,
    phoneNumber,
    checkPhoneExistsError,
    isPhoneVerificationRequired
  } = useSelector((state: StateParams) => state.practice)
  const {sendCodeError, isSentSuccessfully} = useSelector((state: StateParams) => state.account)
  const { languagePreference } = useSelector((state: StateParams) => state.account)
  const {languagePreferenceOnAdminFlow} = useSelector((state: StateParams) => state.screener)
  const {jwt, practiceId} = useSelector((state: StateParams) => state.account)

  if (params.slug) {
    slug = params.slug
  } else if (searchParams.get("id")) {
    id = searchParams.get("id")
  }

  useEffect(() => {
    if (slug !== '') {
      dispatch(getPracticeId({slug}))
      dispatch(setSlug({slug}))
    } else if (id && id !== '') {
      dispatch(setPracticeId({practiceId: id}))
    } else {
      setPracticeIdMissing(true)
    }
  }, [slug, id])

  const submitPhoneNumber = useCallback(() => {
    if (requestingPhoneNumber) {
      dispatch(checkIfPhoneNumberExists(requestingPhoneNumber, practiceId as string))
    }
  }, [requestingPhoneNumber])

  useEffect(() => {
    if (!phoneNumber || (phoneNumber !== requestingPhoneNumber) || checkPhoneExistsError) {
      return
    }
    if (accountExistsWithPhoneNumber) {
      if(languagePreferenceOnAdminFlow || languagePreference){
        navigate("/login")
      }
    } else if (accountExistsWithPhoneNumber === false && isPhoneVerificationRequired === true) {
      navigate("/signup/firstName")
    } else if (accountExistsWithPhoneNumber === false) {
      dispatch(sendVerificationCode(phoneNumber))
    }
  }, [phoneNumber, accountExistsWithPhoneNumber, checkPhoneExistsError])

  function renderMissingPracticeIDMessage() {
    return <div className='h-screen mx-auto px-3 bg-sky-light-lg bg-no-repeat bg-cover'>
      <div className='w-full h-32 flex items-center justify-center'>
        <img src={sjCircle} className='h-[200px] mt-10' alt="sjCircle"/>
      </div>
      <div className='w-full h-32 mt-10 flex flex-col items-center justify-center'>
        <p className='text-lg font-bold'>Please scan the QR Code to proceed</p>
      </div>
    </div>;
  }

  return (
    <>
      {(phoneNumber === requestingPhoneNumber && accountExistsWithPhoneNumber === false) ?
        <EnterCode phoneNumber={requestingPhoneNumber} stackName={StackNames.SIGN_UP}/> :
        (!practiceIdMissing && !jwt ?
          <div className='h-screen md:w-full mx-auto px-3 bg-sky-light-lg bg-no-repeat bg-cover'>
            <div className='w-full h-32 flex items-center justify-center'>
              <img src={sjCircle} className='h-[200px] mt-10' alt="sjCircle"/>
            </div>
            <div className='w-full h-32 mt-4 flex flex-col items-center justify-center'>
              <p className='text-lg font-bold'>Phone Number</p>
              <PhoneInput
                data-test="practice-phone-number"
                placeholder="Enter phone number"
                defaultCountry="US"
                onChange={setRequestingPhoneNumber}/>
              {sendCodeError && isSentSuccessfully === false ?
                <span className='text-sm text-red-700 rounded-lg dark:text-red-800'>{sendCodeError}</span> :
                <span></span>}
            </div>
            <div className='w-full h-32 flex flex-col items-center justify-center'>
              <button data-test="practice-continue"
                      className='md:w-60 sm:w-50 w-60 h-10 font-bold rounded-lg  bg-lightOrange text-white'
                      onClick={submitPhoneNumber}>Continue
              </button>
              {checkPhoneExistsError && <span
                className='text-sm text-center mt-0 text-red-700 rounded-lg dark:text-red-800'>{checkPhoneExistsError}</span>}
            </div>
            <div className="w-full flex flex-col items-center justify-center">
              <Link id="RouterNavLink" to={`/adminLogin/${params.slug}`}>Sign-in as Admin</Link>
            </div>

          </div>
          :
          renderMissingPracticeIDMessage())
      }
    </>
  )
}

export default PracticeEntry
