import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { FaHome, FaPowerOff } from 'react-icons/fa'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'
import likeHand from '../../assets/images/emojis/like-hand.png'
import { clearUserId, logoutProvider, logoutUser, setNextUserScreenerId } from '../../store/actions/account.action'
import { clearScreenerQuestions } from '../../store/actions/screenerQuestions.action'
import { clearAllData } from '../../store/actions/signupData.action'
import { StateParams } from '../../store/reducers'

const ThankYou = () => {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const location = useLocation()
    const { t } = useTranslation();
    const { jwt, slug, screenName, provider } = useSelector((state: StateParams) => state.account)
    const { nextScreenerId, isScreenerPositive } = useSelector((state: StateParams) => state.screener)

    const logout = () => {
        dispatch(logoutProvider())
        dispatch(clearScreenerQuestions())
        navigate(`/adminLogin`)
    }

    const home = () => {
        dispatch(clearUserId())
        navigate('/patients?page=1')
    }

    const nextScreener = () => {
        if (nextScreenerId) {
            dispatch(setNextUserScreenerId({
                nextUserScreenerId: nextScreenerId
            }))
        }
        navigate('/screenerQuestion')
    }

    const next = () => {
        navigate('/suicidalHelpline')
    }

    return (
        <>
            {nextScreenerId ?
                (<div className='h-screen mx-auto px-3 bg-sky-light-lg bg-no-repeat bg-cover'>
                    <div className='w-full flex justify-between'>
                        {provider?.jwt ? <FaHome className='mt-2' onClick={home}/> : <></>}
                        <FaPowerOff data-tooltip-target="tooltip-bottom" data-tooltip-placement="bottom" className='m-2' onClick={logout} />
                    </div>
                    <div className='flex mt-56 bg-lightGreen border-borderGreen border-2 items-center'>
                        <p className='mx-5'>{t('completedScreener')}</p>
                        <img src={likeHand} alt={'likeHand'} />
                    </div>
                    <button onClick={nextScreener} className='bg-orange text-white font-bold p-2 rounded-xl w-full mt-6'>
                    {t('nextScreener')}
                    </button>
                </div>) :
                <div className='h-screen mx-auto px-3 bg-sky-light-lg bg-no-repeat bg-cover'>
                    <div className='w-full flex justify-between'>
                        {provider?.jwt ? <FaHome className='mt-2' onClick={home}/> : <></>}
                        <FaPowerOff data-tooltip-target="tooltip-bottom" data-tooltip-placement="bottom" className='m-2' onClick={logout} />
                    </div>
                    <div className='mt-48 items-center'>
                        <p className='text-4xl flex items-center justify-center font-bold'>{t('thankYou')}</p>
                        <p className='mx-5 p-4 flex items-center justify-center'>{t('completedQuestionnaires')}</p>
                        {screenName === 'adminLogin' ? <p className= 'mx-5 py-2 flex items-center justify-center'>{t('handover')}</p> : ''}
                    </div>
                    {isScreenerPositive ?
                        <button onClick={next} className='bg-orange text-white font-bold p-2 rounded-xl w-full mt-6'>
                            {t('proceedButton')}
                        </button> :
                        <></>
                    }

                </div>
            }
        </>

    )
}

export default ThankYou
