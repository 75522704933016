import {call, put, takeLatest} from "redux-saga/effects";
import {
    accountExistsWithPhoneNumber,
    CHECK_PHONE_NUMBER,
    checkIfPhoneNumberExistsFailed
} from "../actions/practice.action";
import {callFinished, callInProgress} from "../actions/loading.action";
import {ICheckIfEMRNumberExists, ICheckIfPhoneNumberExists} from "../../models";
import {apiHttpClient} from "../../lib";
import {AxiosResponse} from "axios";
import {
    accountExistsWithEMRNumber,
    CHECK_EMR_VALIDITY,
    checkIfEMRNumberExistsFailed
} from "../actions/signupData.action";


const apiCheckIfPhoneNumberExists = (payload: {phoneNumber: string, practiceId: string}) => {
    return apiHttpClient.get<ICheckIfPhoneNumberExists>(`/accounts/check-exists`, {params: {phoneNumber: payload.phoneNumber, id: payload.practiceId}})
        .then((response: AxiosResponse) => {
            return response.data.data as ICheckIfPhoneNumberExists
        }).catch((e) => {
            console.log(e)
            console.log("FailedCheckingIfPhoneNumberExists")
            throw e
        });
}

function* checkIfPhoneNumberExists(action: { type: string,  payload: {phoneNumber: string, practiceId: string} }) {
    try {
        console.log(`PracticeSaga:checkIfPhoneNumberExists`)
        yield put(callInProgress())
        const check: ICheckIfPhoneNumberExists = yield call(apiCheckIfPhoneNumberExists, action.payload)
        console.log(`PracticeSaga:checkIfPhoneNumberExists->\n: ${check} `)
        yield put(accountExistsWithPhoneNumber({...check, phoneNumber: action.payload.phoneNumber}));
    } catch (e: any) {
        console.log(e)
        const msg = e?.response?.data?.error
        yield put(checkIfPhoneNumberExistsFailed({error: msg ? msg : "Error checking if phone number exists!"}));
    } finally {
        yield put(callFinished())
    }
}

const apiCheckIfEMRNumberExists = (payload: {emrId: string, practiceId: string}) => {
    return apiHttpClient.get<ICheckIfEMRNumberExists>(`/accounts/check-exists-with-emr`, {params: {emrId: payload.emrId, id: payload.practiceId}})
        .then((response: AxiosResponse) => {
            return response.data.data as ICheckIfEMRNumberExists
        }).catch((e) => {
            console.log(e)
            console.log("FailedCheckingIfEMRNumberExists")
            throw e
        });
}

function* checkIfEMRNumberExists(action: { type: string,  payload: {emrId: string, practiceId: string} }) {
    try {
        console.log(`PracticeSaga:checkIfEMRNumberExists`)
        yield put(callInProgress())
        const check: ICheckIfEMRNumberExists = yield call(apiCheckIfEMRNumberExists, action.payload)
        console.log(`PracticeSaga:checkIfEMRNumberExists->\n: ${check} `)
        yield put(accountExistsWithEMRNumber({...check, emrId: action.payload.emrId}));
    } catch (e: any) {
        console.log(e)
        const msg = e?.response?.data?.error
        yield put(checkIfEMRNumberExistsFailed({error: msg ? msg : "Error checking if EMR Number exists!"}));
    } finally {
        yield put(callFinished())
    }
}
export default function* practiceSaga() {
    yield takeLatest(CHECK_PHONE_NUMBER, checkIfPhoneNumberExists)
    yield takeLatest(CHECK_EMR_VALIDITY, checkIfEMRNumberExists)
}
