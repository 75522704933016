import {AgeGroup, ICheckIfEMRNumberExists, LanguageCodes} from "../../models"

export const CHECK_EMR_VALIDITY = "CHECK_EMR_VALIDITY"
export const CHECK_EMR_VALIDITY_FAILED = "CHECK_EMR_VALIDITY_FAILED"
export const CHECK_EMR_VALIDITY_RESPONSE = "CHECK_EMR_VALIDITY_RESPONSE"
export const CLEAR_EMR_CHECK = "CLEAR_EMR_CHECK"
export const SET_SIGNUP_DATA = "SET_SIGNUP_DATA"
export const SET_FIRSTNAME = "SET_FIRSTNAME"
export const SET_EMR_NUMBER = "SET_EMR_NUMBER"
export const SET_LASTNAME = "SET_LASTNAME"
export const SET_DOB = "SET_DOB"
export const SET_GENDER = "SET_GENDER"
export const SET_ZIPCODE = "SET_ZIPCODE"
export const SET_PASSWORD = "SET_PASSWORD"
export const SET_WHO_IS_FILLING = "SET_WHO_IS_FILLING"
export const SET_LEGAL_GUARDIAN_DATA = "SET_LEGAL_GUARDIAN_DATA"
export const SET_IS_LEGAL_GUARDIAN = "SET_IS_LEGAL_GUARDIAN"
export const SET_IS_PATIENT_CAPABLE = "SET_IS_PATIENT_CAPABLE"
export const SET_EMERGENCY_CONTACT = "SET_EMERGENCY_CONTACT"
export const SET_GUARDIAN_FIRSTNAME = "SET_GUARDIAN_FIRSTNAME"
export const SET_GUARDIAN_LASTNAME = "SET_GUARDIAN_LASTNAME"
export const SET_GUARDIAN_EMAIL = "SET_GUARDIAN_EMAIL"
export const SET_EMERGENCY_FIRSTNAME = "SET_EMERGENCY_FIRSTNAME"
export const SET_EMERGENCY_LASTNAME = "SET_EMERGENCY_LASTNAME"
export const SET_EMERGENCY_EMAIL = "SET_EMERGENCY_EMAIL"
export const SET_EMERGENCY_PHONENUMBER = "SET_EMERGENCY_PHONENUMBER"
export const SET_EMERGENCY_RELATIONTYPE = "SET_EMERGENCY_RELATIONTYPE"
export const SET_EMERGENCY_CANSHARE = "SET_EMERGENCY_CANSHARE"
export const CLEAR_ALL_DATA = "CLEAR_ALL_DATA"

export const checkIfEMRIDisAlreadyUsed = (emrId: string, practiceId: string) => {
    return { type: CHECK_EMR_VALIDITY, payload: { emrId, practiceId } }
}

export const accountExistsWithEMRNumber = (payload: {emrId: string} & ICheckIfEMRNumberExists) => {
    return {type: CHECK_EMR_VALIDITY_RESPONSE, payload}
}

export const checkIfEMRNumberExistsFailed = (payload: {error: string}) => {
    return {type: CHECK_EMR_VALIDITY_FAILED, payload}
}
export const clearEMRCheck = () => {
    return {type: CLEAR_EMR_CHECK}
}
export const signupData = (payload: {firstName: string, lastName: string, phoneNumber: string, password: string,
    dob: string, hasAcceptedTerms: boolean, isLegalGuardian: boolean, isOneAmongMultipleChildren: boolean,
    hasPrematureBirth: boolean, zipCode: string, gender: string, ageGroup: AgeGroup | undefined, preferredLanguage: LanguageCodes | undefined}) => {
    return {type: SET_SIGNUP_DATA, payload}
}

export const setFirstName = (payload: {firstName: string}) => {
    return {type: SET_FIRSTNAME, payload}
}

export const setEMRNumber = (payload: {emrId: string}) => {
    return {type: SET_EMR_NUMBER, payload}
}
export const setLastName = (payload: {lastName: string}) => {
    return {type: SET_LASTNAME, payload}
}
export const setDob = (payload: {dob: string, ageGroup: AgeGroup | undefined}) => {
    return {type: SET_DOB, payload}
}
export const setGender = (payload: {gender: string}) => {
    return {type: SET_GENDER, payload}
}
export const setZipCode = (payload: {zipCode: string}) => {
    return {type: SET_ZIPCODE, payload}
}
export const setPassword = (payload: {password: string}) => {
    return {type: SET_PASSWORD, payload}
}
export const setWhoIsFilling = (payload: {isLegalGuardian: boolean, phoneNumber: string, hasAcceptedTerms: boolean, isOneAmongMultipleChildren: boolean,
    hasPrematureBirth: boolean, preferredLanguage: LanguageCodes | undefined }) => {
    return {type: SET_WHO_IS_FILLING, payload}
}
export const setGuardianFirstName = (payload: {guardianFirstName: string}) => {
    return {type: SET_GUARDIAN_FIRSTNAME, payload}
}
export const setGuardianLastName = (payload: {guardianLastName: string}) => {
    return {type: SET_GUARDIAN_LASTNAME, payload}
}
export const setGuardianEmail = (payload: {guardianEmail: string}) => {
    return {type: SET_GUARDIAN_EMAIL, payload}
}
export const setEmergencyFirstName = (payload: {guardianFirstName: string}) => {
    return {type: SET_EMERGENCY_FIRSTNAME, payload}
}
export const setEmergencyLastName = (payload: {guardianLastName: string}) => {
    return {type: SET_EMERGENCY_LASTNAME, payload}
}
export const setEmergencyEmail = (payload: {guardianEmail: string}) => {
    return {type: SET_EMERGENCY_EMAIL, payload}
}
export const setEmergencyPhoneNumber = (payload: {guardianPhoneNumber: string}) => {
    return {type: SET_EMERGENCY_PHONENUMBER, payload}
}
export const setEmergencyRelationType = (payload: {relationshipWithPatient: string}) => {
    return {type: SET_EMERGENCY_RELATIONTYPE, payload}
}
export const setEmergencyCanShare = (payload: {canShareEmergencyContact: boolean}) => {
    return {type: SET_EMERGENCY_CANSHARE, payload}
}

export const clearAllData = () => {
    return {type: CLEAR_ALL_DATA}
}

export const legalGuardianData = (payload: { guardianEmail: string, guardianFirstName: string, guardianLastName: string}) => {
    return {type: SET_LEGAL_GUARDIAN_DATA, payload}
}

export const emergencyContactData = (payload: { guardianEmail: string, guardianFirstName: string, guardianLastName: string, guardianPhoneNumber: string, relationshipWithPatient: string, canShareEmergencyContact: boolean}) => {
    return {type: SET_EMERGENCY_CONTACT, payload}
}

export const setIsLegalGuardian = (payload: {isLegalGuardian: boolean}) => {
    return {type: SET_IS_LEGAL_GUARDIAN, payload}
}

export const setIsPatientCapable = (payload: {isPatientCapable: boolean}) => {
    return {type: SET_IS_PATIENT_CAPABLE, payload}
}
