import { call, put, takeLatest } from "redux-saga/effects";
import { apiHttpClient } from "../../lib";
import {
    CreateAccountAPIRequest,
    ICheckIfCodeVerified,
    ICheckIfVerificationCodeSentSuccessfully,
    ICreateAccountRequest,
    IPractice,
    IUserAccount,
    IProviderUser,
    IAccount,
    PatientScreener
} from "../../models";
import { AxiosResponse } from "axios";
import {
    accountCreated,
    accountCreationFailed,
    accountSignedIn,
    accountSignInFailed,
    ACCOUNT_LOGIN,
    checkIfCodeVerifiedSuccessfully,
    checkIfCodeVerifiedSuccessfullyFailed,
    checkIfVerificationCodeSent,
    checkIfVerificationCodeSentFailed,
    CREATE_ACCOUNT,
    getPracticeIdFailed,
    getPracticeIdSuccess,
    GET_PRACTICE_ID,
    resetPasswordCompleted,
    resetPasswordFailed,
    RESET_PASSWORD,
    SEND_VERIFICATION_CODE,
    VERIFY_CODE,
    PROVIDER_LOGIN, providerLoginFailed, providerLoginSuccess, PATIENT_SCREENER_START, patientScreenerStartingFailed, patientScreenerStarted, acceptTermsFailed, acceptTermsSuccess, ACCEPT_TERMS
} from "../actions/account.action";
import { callFinished, callInProgress } from "../actions/loading.action";

    const apiCreateAccount = (payload: any) => {
    const config = {headers: {"x-admin-token": payload.account.adminToken, "x-practice-id": payload.account.practiceId}}
        const request = {...payload.createAccountRequest, practiceId: payload.account.practiceId};
        return apiHttpClient.post<IUserAccount>(`/admin/patients`, request, config)
        .then((response: AxiosResponse) => {
            return response.data.data as IUserAccount
        }).catch((e) => {
            console.log("Failed Creating Account")
            throw e
        });
}

const apiSignInAccount = (signInRequest: {phoneNumber: string, password: string, practiceId: string}) => {
    return apiHttpClient.post<IUserAccount>(`/accounts/login`, signInRequest)
        .then((response: AxiosResponse) => {
            return response.data.data as IUserAccount
        }).catch((e) => {
            console.log("Failed Signing Into Account")
            throw e
        });
}

const apiSendVerificationCode = (request: { phoneNumber: string }) => {
    return apiHttpClient.post<ICheckIfVerificationCodeSentSuccessfully>(`/accounts/send-code`, request)
        .then((response: AxiosResponse) => {
            return response.data.data as ICheckIfVerificationCodeSentSuccessfully
        }).catch((e) => {
            throw "Failed sending verification code"
        });
}

const apiVerifyCode = (request: { phoneNumber: string, verificationCode: string }) => {
    return apiHttpClient.post<ICheckIfCodeVerified>(`/accounts/verify-code`, request)
        .then((response: AxiosResponse) => {
            return response.data.data as ICheckIfCodeVerified
        }).catch((e) => {
            throw "Code is invalid. Plesae enter correct code"
        });
}

const apiResetPassword = (request: { phoneNumber: string, password: string }) => {
    return apiHttpClient.post<IUserAccount>(`/accounts/reset-password`, request)
    .then((response: AxiosResponse) => {
        return response.data.data as IUserAccount
    }).catch((e) => {
        throw "Failed Signing Into Account"
    });
}

const apiAcceptTermsAndConditions = (account: { token: string, adminToken: string, practiceId: string, userId: string }) => {
    const config = { headers: { "x-auth-token": account.token, "x-user-id": account.userId,
    "x-admin-token": account.adminToken, "x-practice-id": account.practiceId }}
    return apiHttpClient.put<{isTermsAccepted: boolean}>(`/accept-terms`, {},config)
    .then((response: AxiosResponse) => {
        return response.data.data as {isTermsAccepted: boolean}
    }).catch((e) => {
        throw "Failed accepts terms and condition"
    });
}

const apiGetPracticeId = (payload: { slug: string }) => {
    return apiHttpClient.get<IPractice>(`/accounts/practiceId`, {params: {slug: payload.slug}})
    .then((response: AxiosResponse) => {
        return response.data.data as IPractice
    }).catch((e) => {
        throw "Failed to get practice Id"
    });
}

const apiDoProviderLogin = (user: IProviderUser) => {
    return apiHttpClient.post<IAccount>(`/accounts/v2/admin-login`, user)
      .then((response: AxiosResponse) => {
          return response.data.data as IAccount
      }).catch(() => {
          throw "Failed to get Login Provider details"
      });
}

const apiPatientScreener = (request: {userId: string, practiceType?: string, adminToken: string, practiceId: string}) => {
    const config = { headers: { "x-admin-token": request.adminToken, "x-practice-id": request.practiceId, "x-user-id": request.userId }}
    return apiHttpClient.post<PatientScreener>(`/admin/screen/patient/${request.userId}`, {practiceType: request.practiceType}, config)
        .then((response: AxiosResponse) => {
            return response.data.data as PatientScreener
        }).catch((e) => {
            console.log("Failed fetching patient details")
            throw e
        });
}

function* createUserAccount(action: { type: string, payload: ICreateAccountRequest, account: {practiceId: string, adminToken: string}  }) {
    try {
        console.log(`AccountSaga:createUserAccount`)
        yield put(callInProgress())
        const userAccount: IUserAccount = yield call(apiCreateAccount, {createAccountRequest: action.payload, account: action.account})
        console.log(`AccountSaga:createUserAccount->\n fetched account details: ${userAccount.jwt}`)
        if (userAccount.error) {
            yield put(accountCreationFailed({ error: userAccount.error }));
        } else if (userAccount.jwt) {
            yield put(accountCreated({ jwt: userAccount.jwt, id: userAccount.id, phoneNumber: userAccount.phoneNumber, nickname: userAccount.nickname, userScreenerId: userAccount.userScreenerId, userScreenerSlug: userAccount.userScreenerSlug, preferredLanguage: userAccount.preferredLanguage }));
        } else {
            yield put(accountCreationFailed({ error: "Unknown error occurred creating account!" }));
        }
    } catch (e: any) {
        console.log(e)
        const statusCode = e?.response?.status
        yield put(accountCreationFailed({ error: statusCode === 404 ? 'No screener is available for your age. Please see your care provider' : "Error while creating account!" }));
    } finally {
        yield put(callFinished())
    }
}

function* signInAccount(action: { type: string,  payload: {phoneNumber: string, password: string}, account: {practiceId: string} }) {
    try {
        console.log(`AccountSaga:signinAccount`)
        yield put(callInProgress())
        const signInAccount: IUserAccount = yield call(apiSignInAccount, {...action.payload, practiceId: action.account.practiceId})
        console.log(`AccountSaga:signinAccount->\n fetched account details: ${signInAccount.jwt} `)
        if (signInAccount.error) {
            yield put(accountSignInFailed({error: signInAccount.error}));
        } else if(signInAccount.jwt) {
            yield put(accountSignedIn({jwt: signInAccount.jwt, id: signInAccount.id, phoneNumber: signInAccount.phoneNumber, nickname: signInAccount.nickname, userScreenerId: signInAccount.userScreenerId, userScreenerSlug: signInAccount.userScreenerSlug, hasAcceptedTerms: signInAccount.hasAcceptedTerms}));
        } else {
            yield put(accountSignInFailed({error: "Unknown error occurred signing into account!"}));
        }
    } catch (e: any) {
        console.log(e)
        const statusCode = e?.response?.status
        const message = e?.response?.data.error
        yield put(accountSignInFailed({error: (statusCode >=400 && statusCode < 500) && message ? message : "Error signing into account!"}));
    } finally {
        yield put(callFinished())
    }
}

function* sendVerificationCode(action: { type: string, payload: { phoneNumber: string } }) {
    try {
        console.log(`AccountSaga: sendVerificationCode`)
        yield put(callInProgress())
        const check: ICheckIfVerificationCodeSentSuccessfully = yield call(apiSendVerificationCode, {...action.payload})
        console.log(`AccountSaga:sendVerificationCode->\n is code sent successfully: ${check.isSentSuccessfully} `)
        yield put(checkIfVerificationCodeSent({...check, phoneNumber: action.payload.phoneNumber}))
    } catch (e) {
        console.log(e)
        yield put(checkIfVerificationCodeSentFailed({error: "There was an error in sending the code", isSentSuccessfully: false}));
    }  finally {
        yield put(callFinished())
    }
}

function* verifyCode(action: { type: string, payload: { phoneNumber: string, verificationCode: string } }) {
    try {
        console.log(`AccountSaga: verifyCode`)
        yield put(callInProgress())
        const check: ICheckIfCodeVerified = yield call(apiVerifyCode, {...action.payload})
        console.log(`AccountSaga:verifyCode->\n is code sent successfully: ${check.isVerified} `)
        yield put(checkIfCodeVerifiedSuccessfully({...check, phoneNumber: action.payload.phoneNumber}))
    } catch (e: any) {
        console.log(e)
        yield put(checkIfCodeVerifiedSuccessfullyFailed({error: e, isVerified: false}));
    } finally {
        yield put(callFinished())
    }
}

function* resetPassword(action: { type: string, payload: { phoneNumber: string, password: string } }) {
    try {
        console.log(`AccountSaga: resetPassword`)
        yield put(callInProgress())
        const resetPassword: IUserAccount = yield call(apiResetPassword, { ...action.payload })
        console.log(`AccountSaga:resetPassword->\n fetched account details: ${resetPassword.jwt} `)
        if (resetPassword.error) {
            yield put(resetPasswordFailed({error: resetPassword.error}));
        } else if(resetPassword.jwt) {
            yield put(resetPasswordCompleted({jwt: resetPassword.jwt, id: resetPassword.id, phoneNumber: resetPassword.phoneNumber,
                nickname: resetPassword.nickname, userScreenerId: resetPassword.userScreenerId, hasAcceptedTerms: resetPassword.hasAcceptedTerms}));
        } else {
            yield put(resetPasswordFailed({error: "Unknown error occurred resetting password!"}));
        }
    } catch (e) {
        console.log(e)
        yield put(resetPasswordFailed({error: "Error resetting password!"}));
    } finally {
        yield put(callFinished())
    }
}

function* getPracticeId(action: { type: string, payload: { slug: string } }) {
    try {
        console.log(`AccountSaga: getPracticeId`)
        yield put(callInProgress())
        const practiceId: IPractice = yield call(apiGetPracticeId, { ...action.payload })
        console.log(`AccountSaga:getPracticeId->\n fetched practice id: ${practiceId.practiceId} `)
        if (practiceId.error) {
            yield put(getPracticeIdFailed({error: practiceId.error}));
        } else if(practiceId.practiceId) {
            yield put(getPracticeIdSuccess({practiceId: practiceId.practiceId}));
        } else {
            yield put(getPracticeIdFailed({error: "Unknown error occurred getting practice id!"}));
        }
    } catch (e) {
        console.log(e)
        yield put(getPracticeIdFailed({error: "Error getting practice id!"}));
    } finally {
        yield put(callFinished())
    }
}

function* doProviderLogin(action: {type: string, user: IProviderUser}) {
    try {
        console.log(`AccountSaga: Provider Login`);
        yield put(callInProgress())
        const provider: IAccount = yield call(apiDoProviderLogin, {...action.user});
        console.log(`AccountSaga:doProviderLogin->\n fetched Provide data while login : ${provider} `)
        if(provider.error){
            yield put(providerLoginFailed({error: provider.error}));
        } else if (provider) {
            yield put(providerLoginSuccess(provider))
        } else {
            yield put(providerLoginFailed({error: "Unknown error occurred while Provider Login"}));
        }
    } catch (e: any) {
        console.log(e)
        const statusCode = e?.response?.status
        const message = e?.response?.data.error
        yield put(providerLoginFailed({error: (statusCode >=400 && statusCode < 500) && message ? message : "Error getting Provider Login!"}));
    } finally {
        yield put(callFinished())
    }
}

function* patientScreenerStart(action: { type: string,  payload: {userId: string, practiceType?: string}, account: {practiceId: string, adminToken: string} }) {
    try {
        console.log(`AccountSaga:patientScreener`)
        yield put(callInProgress())
        const patientScreener: PatientScreener = yield call(apiPatientScreener, {...action.payload, practiceId: action.account.practiceId, adminToken: action.account.adminToken})
        console.log(`AccountSaga:patientScreener->\n fetched account details: ${patientScreener.id} `)
        if (patientScreener.error) {
            yield put(patientScreenerStartingFailed({error: patientScreener.error}));
        } else if(patientScreener.id) {
            yield put(patientScreenerStarted({id: patientScreener.id, userScreenerId: patientScreener.userScreenerId, userScreenerSlug: patientScreener.userScreenerSlug}));
        } else {
            yield put(patientScreenerStartingFailed({error: "Unknown error occurred fetching patient details!"}));
        }
    } catch (e: any) {
        console.log(e)
        const statusCode = e?.response?.status
        const message = e?.response?.data.error
        yield put(patientScreenerStartingFailed({error: (statusCode >=400 && statusCode < 500) && message ? message : "Error fetching patient details!"}));
    } finally {
        yield put(callFinished())
    }
}

function* acceptTermsAndConditions(action: { type: string, account: {token: string, adminToken: string, practiceId: string, userId: string }}) {
    try {
        console.log(`AccountSaga: acceptTermsAndConditions`)
        yield put(callInProgress())
        const response: {isTermsAccepted: boolean} = yield call(apiAcceptTermsAndConditions, { ...action.account })
        console.log(`AccountSaga:acceptTermsAndConditions `)
        yield put(acceptTermsSuccess({ isTermsAccepted: response.isTermsAccepted }));
    } catch (e) {
        console.log(e)
        yield put(acceptTermsFailed({error: "Error getting practice id!"}));
    } finally {
        yield put(callFinished())
    }
}

export default function* accountSaga() {
    yield takeLatest(CREATE_ACCOUNT, createUserAccount)
    yield takeLatest(ACCOUNT_LOGIN, signInAccount)
    yield takeLatest(SEND_VERIFICATION_CODE, sendVerificationCode)
    yield takeLatest(VERIFY_CODE, verifyCode)
    yield takeLatest(RESET_PASSWORD, resetPassword)
    yield takeLatest(GET_PRACTICE_ID, getPracticeId)
    yield takeLatest(PROVIDER_LOGIN, doProviderLogin)
    yield takeLatest(PATIENT_SCREENER_START, patientScreenerStart)
    yield takeLatest(ACCEPT_TERMS, acceptTermsAndConditions)
}
