import TablePagination from "../generic/TablePagination";
import React, {useMemo} from "react";
import {DateTime} from "luxon";
import {IPatientSummary} from "../../models";
import {ViewPatientDetailsAction} from "../generic/ViewPatientDetailsAction";

interface PatientsProps {
  patients: IPatientSummary[]
}

export const Patients: React.FC<PatientsProps> = ({patients}) => {

  const data = patients.map((patient) => {
    const dob: string = DateTime.fromISO(patient.dob.toString(), {zone: 'utc'}).toFormat('MM-dd-yyyy')
    return {
      name: `${patient.lastName}, ${patient.firstName}`,
      dateOfBirth: dob,
      gender: patient.gender,
      details: {patientId: patient.id }
    }
  })

  const columns = useMemo(
    () => [
      {Header: "Patient Name", accessor: "name"},
      {Header: "Date of birth", accessor: "dateOfBirth"},
      {Header: "Gender", accessor: "gender"},
      {Header: "View", accessor: "details", Cell: ViewPatientDetailsAction},
    ],
    []
  );

  return (
    <div className="h-[79%]">
      <TablePagination columns={columns} data={data}/>
    </div>
  )
}
