import React, {useEffect} from "react";
import { useSelector } from "react-redux";
import { StateParams } from "../../store/reducers";
import { LottieComponent } from "../animations/LottieComponent";
import loadingAnimation from "../../assets/data/loadingAnimation.json"
import i18n from "i18next";

export function LanguagePreferenceComponent(props: any) {
    const { languagePreference } = useSelector((state: StateParams) => state.account)
    const { languagePreferenceOnExistence } = useSelector((state: StateParams) => state.practice)
    const { languagePreferenceOnAdminFlow } = useSelector((state: StateParams) => state.screener)

    useEffect(() => {
        i18n.changeLanguage(languagePreference || languagePreferenceOnExistence || languagePreferenceOnAdminFlow)
    }, [languagePreference, languagePreferenceOnExistence, languagePreferenceOnAdminFlow])

    return <></>
}
