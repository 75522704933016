import React, {useCallback, useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import sjCircle from "../../assets/images/loginBackground/sj-circle.png";
import {checkIfPhoneNumberExists} from "../../store/actions/practice.action";
import {StateParams} from "../../store/reducers";
import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-number-input'
import {ExclamationTriangleIcon} from '@heroicons/react/20/solid'
import {
    checkIfEMRIDisAlreadyUsed,
    clearAllData,
    clearEMRCheck,
    setEMRNumber
} from "../../store/actions/signupData.action";

const AdminFlowPhoneNumberEntry: React.FC = () => {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const [requestingPhoneNumber, setRequestingPhoneNumber] = useState<string>()
    const [requestingEMRId, setRequestingEMRId] = useState<string>()
    const [formHasErrors, setFormHasErrors] = useState<boolean>(false)
    const [errorMessage, setErrorMessage] = useState<string>()
    const {
        accountExistsWithPhoneNumber,
        phoneNumber,
        checkPhoneExistsError
    } = useSelector((state: StateParams) => state.practice)
    const {practiceId} = useSelector((state: StateParams) => state.account)
    const [showPhoneWarningModal, setShowPhoneWarningModal] = useState<boolean>(false)
    const { emrId } = useSelector((state: StateParams) => state.signup)
    const { emrCheck } = useSelector((state: StateParams) => state.signup)
    const ctaStyle = `md:w-60 sm:w-50 w-60 h-10 font-bold rounded-lg  bg-lightOrange text-white`

    useEffect(() => {
        dispatch(clearAllData())
    }, [])

    const submitPhoneNumber = useCallback(() => {
        if (requestingPhoneNumber) {
            dispatch(checkIfPhoneNumberExists(requestingPhoneNumber, practiceId as string))
        }
    }, [requestingPhoneNumber])

    const checkEMRIsValid = useCallback(() => {
        if (requestingEMRId && requestingEMRId.length > 0) {
            dispatch(checkIfEMRIDisAlreadyUsed(requestingEMRId, practiceId as string))
        }
    }, [requestingEMRId])

    const onEMRInputChange = (e: any) => {
        e.preventDefault()
        if (e.target.id) {
            setRequestingEMRId(e.target.value)
        }
    }

    useEffect(() => {
        if (!phoneNumber || (phoneNumber !== requestingPhoneNumber) || checkPhoneExistsError) {
            return
        }
        if (accountExistsWithPhoneNumber) {
            setShowPhoneWarningModal(true)
        } else if (accountExistsWithPhoneNumber === false) {
            console.log("Phone number doesnt exist already, navigation to capture first name")
            navigate("/signup/firstName")
        }
    }, [phoneNumber, accountExistsWithPhoneNumber, checkPhoneExistsError])

    useEffect(() => {
        if(!requestingEMRId) {
            setFormHasErrors(false)
            dispatch(clearEMRCheck())
            return
        }
        if(emrCheck?.exists) {
            setFormHasErrors(true)
        }
        if(emrCheck?.error) {
            setFormHasErrors(true)
            setErrorMessage("Error occurred checking for EMR Number validity.")
            return
        }

        console.log("Storing EMR in signup data", requestingEMRId)
        dispatch(setEMRNumber({emrId: requestingEMRId}))

    }, [requestingEMRId, emrCheck?.exists, emrCheck?.error])

    useEffect(() => {
        if(checkPhoneExistsError) {
            setErrorMessage(checkPhoneExistsError)
            return
        }
    }, [checkPhoneExistsError]);

    return (
        <>
            <div className='h-screen md:w-full mx-auto px-3 bg-sky-light-lg bg-no-repeat bg-cover'>
                <div className='w-full h-32 flex items-center justify-center'>
                    <img src={sjCircle} className='h-[200px] mt-10' alt="sjCircle"/>
                </div>
                <div className='w-full mt-4 flex flex-col items-center justify-center gap-2'>
                    <div className='w-full mt-4 flex flex-col items-center justify-center'>
                        <p className='text-lg font-bold'>EMR Number</p>
                        <input id='emrId' type='text' required
                               onChange={onEMRInputChange}
                               onBlur={checkEMRIsValid}
                               className='w-[50%] mt-4 rounded-md h-8 border-gray-300 focus:outline-none focus:border-orange focus:ring-1 focus:ring-orange'/>
                        {emrCheck?.exists && <span
                            className='text-sm text-center mt-0 text-red-700 rounded-lg dark:text-red-800'>{`EMR Number is already associated with another patient with last name ${emrCheck.lastName}. Please check again.`}</span>}
                    </div>
                    <div className='w-full mt-4 flex flex-col items-center justify-center'>
                        <p className='text-lg font-bold'>Phone Number</p>
                        <PhoneInput
                            className={'mt-4 h-8'}
                            data-test="practice-phone-number"
                            placeholder="Enter phone number"
                            defaultCountry="US"
                            onChange={setRequestingPhoneNumber}/>
                    </div>
                </div>
                <div className='w-full h-32 flex flex-col items-center justify-center'>
                    <button data-test="practice-continue"
                            disabled={formHasErrors}
                            className={formHasErrors ? `${ctaStyle} bg-gray-300` : ctaStyle}
                            onClick={submitPhoneNumber}>Continue
                    </button>
                    {errorMessage && <span
                        className='text-sm text-center mt-0 text-red-700 rounded-lg dark:text-red-800'>{errorMessage}</span>}
                </div>
            </div>
            {showPhoneWarningModal &&
                <div
                    className={'fixed left-0 top-0 bg-black bg-opacity-50 w-screen h-screen flex justify-center items-center'}>
                    <div className={'bg-white rounded shadow-md p-8 w-[40%] flex flex-col gap-5'}>
                        <div className={'flex gap-5'}>
                            <div className={'flex justify-center items-center'}>
                                <ExclamationTriangleIcon className="h-5 w-5 text-red-500" aria-hidden="true"/>
                            </div>
                            <div className={'flex-grow'}>
                                <h1 className={'font-bold text-xl mb-3'}>Duplicate Phone Number</h1>
                                <p>The entered phone number <b>{phoneNumber}</b> is already registered with another
                                    Patient. Are you
                                    sure you want to continue ?</p>
                            </div>
                        </div>
                        <div className={"flex self-center gap-10"}>
                            <button className='p-2 bg-gray-300 rounded-md text-white'
                                    onClick={() => setShowPhoneWarningModal(false)}>Cancel
                            </button>
                            <button className='p-2 bg-lightOrange rounded-md text-white' onClick={() => {
                              navigate("/signup/firstName")
                            }}>Continue
                            </button>
                        </div>
                    </div>

                </div>
            }
        </>
    )
}

export default AdminFlowPhoneNumberEntry
