import { useTranslation } from 'react-i18next'

const Alcohol = () => {
    const { t } = useTranslation();
    return (
        <div className='grid grid-cols-4 place-items-center mt-2 gap-4 bg-[#FFF5EA] border-solid border-2 border-transparent border-b-[#FFA200]'>
            <div className="ml-5">
                <span className="text-md font-bold font-sans">{t('oneDrinkEquals')}:</span>
            </div>
            <div className="w-full h-full flex items-center">
                <div className="bg-ph-beer-stein-fill w-10 h-10 bg-cover bg-no-repeat p-5"></div>
                <span className="text-sm font-sans">12 {t('ozBeer')}</span>
            </div>
            <div className="w-full h-full flex items-center ">
                <div className="bg-bxs-wine w-10 h-10 bg-cover bg-no-repeat p-5"></div>
                <span className="text-sm font-sans">5 {t('ozWine')}</span>
            </div>
            <div className="w-full h-full flex items-center">
                <div className="bg-ion-wine-sharp w-10 h-10 bg-cover bg-no-repeat p-5"></div>
                <span className="text-sm font-sans">1.5 {t('ozLiquor')} <span className="text-xs">({t('ozLiquorOneShot')})</span></span>
            </div>
        </div>)
}

export default Alcohol