export const TRANSLATIONS_ES = {
    signupTitle: "Regístrate",
    signupHeader: "Por favor ingrese la siguiente información para crear una cuenta",
    mobileNumber: "Número de teléfono móvil",
    patientFirstname: "Primer nombre del paciente",
    patientLastName: "Apellido del paciente",
    patientDob: "Fecha de nacimiento del paciente",
    patientGender: "Sexo del paciente",
    male: "Masculino",
    female: "Femenino",
    zipCode: "Código postal",
    password: "contraseña",
    whoIsFillingForm: "¿Quién está llenando la información?",
    legalGuardian: "Guardián legal",
    patient: "Paciente",
    proceedButton: "Continuar",
    belowTwelveNote: "Por tu edad, necesitamos que entregues el dispositivo a tus padres o pídeles que te registren.",
    parent: "Si, soy unos de los padres",
    notParent: "No, no soy unos del los padres",
    twelveToEighteenNote: "Por la edad del paciente, necesitamos su opinión. Entregue el dispositivo a el paciente para registrarse si es capaz de responder la encuesta.",
    imPatient: "Si, soy la/el paciente",
    notPatient: "No, soy la/el paciente",
    patientIncapable: "La/el paciente no tiene la capacidad",
    legalGuardianTitle: "ingrese los detalles",
    guardianFirstName: "Primer Nombre guardián",
    guardianLastName: "Apellido del guardián",
    guardianEmail: "Dirección de correo electrónico",
    emergencyContactTitle: "Ingresa tu contacto de emergencia",
    emergencyFirstName: "Primer nombre",
    emergencyLastName: "Apellido",
    emergencyPhoneNumber: "Número de teléfono",
    relationType: "Tipo de relación",
    canShareDetail: "En caso de emergencia,, podemos compartir su detalles con la persona de contacto anterior",
    yes: "Si",
    no: "No",
    reasonForVisitTitle: "Motivo de la visita",
    sickVisit: "Visita por enfermedad",
    annualVisit: "Visita anual",
    welcomeTitle: "Introducción al cuestionario",
    welcomeSubTitle: "Identifique cualquier síntoma respondiendo las próximas preguntas, tomará de 8 a 10 minutos de su tiempo.",
    getStartedButton: "Empezar",
    adhdNote: "La siguiente encuesta debe ser completada por su guardian legal o padre; entregue el dispositivo.",
    handoverButton: "Entregar",
    loginTitle: "Acceso",
    loginSubTitle: "Su información permanecerá segura y confidencial.",
    forgotPassword: "¿Has olvidado tu contraseña?",
    forgotPasswordSubTitle: "Ingrese el número de teléfono que utiliza para iniciar sesión en Small Joys",
    backToLogin: "iniciar sesión",
    resetPassword: "Restablecer la contraseña",
    resetPasswordSubTitle: "Por favor cree una nueva contraseña, su contraseña debe tener al menos 8 caracteres",
    enterCodeTitle: "Por favor ingrese la OTP enviada",
    change: "cambiar",
    verify: "Verificar",
    notReceivedCode: "¿No has recibido tu código?",
    resendCode: "Reenviar codigo",
    completedScreener: "Has completado el cuestionario",
    nextScreener: "Ir al siguiente cuestionario",
    thankYou: "¡gracias!",
    completedQuestionnaires: "Has completado todos los cuestionarios",
    handover: "Entre que el dispositivo electrónico al administrador",
    suicidalTitle: "Tienes que hablar con alguien hoy",
    suicidalSubTitle: "Si está pensando en lastimarse, está preocupado por un amigo o un ser querido, o desea apoyo emocional, llámenos y hable con nosotros (estamos disponible 24/7)",
    call: "Llama",
    officialWebsite: "Sitio web oficial",
    oneDrinkEquals: "Un trago equivale a",
    ozBeer: 'oz de cerveza',
    ozWine: 'oz de vino',
    ozLiquor: 'oz de licor',
    ozLiquorOneShot: 'una copita'
}