import React, { useCallback, useEffect, useState } from 'react';
import sjCircle from "../../assets/images/loginBackground/sj-circle.png";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import { clearErrorMessages, sendVerificationCode, verifyCode } from '../../store/actions/account.action';
import { StateParams } from '../../store/reducers';
import { StackNames } from '../../models';
import { useTranslation } from 'react-i18next';

type ChildProps = {
    phoneNumber: string | undefined
    stackName: StackNames
}

const EnterCode: React.FC<ChildProps> = ({ phoneNumber, stackName }) => {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const [code, setCode] = useState('')
    const { t } = useTranslation();
    const { codeVerifyError, isVerified } = useSelector((state: StateParams) => state.account)

    const verifyPhoneNumber = useCallback(() => {
        if (phoneNumber && code) {
            dispatch(verifyCode(phoneNumber, code))
        }
    }, [phoneNumber, code])

    useEffect(() => {
        if (phoneNumber && isVerified === true && !codeVerifyError && stackName === StackNames.SIGN_UP) {
            navigate("/signup/firstName")
        } else if (phoneNumber &&
            isVerified === true && !codeVerifyError && stackName === StackNames.FORGOT_PASSWORD) {
            navigate('/resetPassword')
        }
    }, [isVerified])

    useEffect(() => {
        dispatch(clearErrorMessages())
    }, [])

    return (
        <> {phoneNumber &&
            <div className='h-screen max-w-[1240px] mx-auto px-3 bg-sky-light-lg bg-no-repeat bg-cover'>
                <div className='w-full h-32 flex items-center justify-center'>
                    <img src={sjCircle} className='h-[200px] mt-10' />
                </div>
                <div className='w-full h-32 mt-10 flex flex-col items-center justify-center'>
                    <p className='mx-5'>
                        <span className='text-lg font-bold'>{t('enterCodeTitle')}</span>
                        <span className='text-lightOrange text-lg font-bold'> {phoneNumber} </span>
                        <span className='text-8xs font-bold underline text-hyperLink hover:text-blue-800 hover:cursor-pointer visited:text-purple-600'
                            onClick={() => { navigate(0) }}>{t('change')}
                        </span>
                    </p>
                    <input className='mt-5' type={"text"} name={"phoneNumber"}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => { setCode(event.target.value) }} />
                    {codeVerifyError && isVerified === false ? <span className='text-sm text-red-700 rounded-lg dark:text-red-800'>{codeVerifyError}</span> :
                        <span></span>}
                </div>
                <div className='w-full h-32 flex flex-col items-center justify-center'>
                    <button className='w-64 h-10 font-bold rounded-lg  bg-lightOrange text-white' onClick={verifyPhoneNumber}>{t('verify')}</button>
                    <span className='text-sm font-bold pt-1'>{t('notReceivedCode')}
                        <span className='text-8xs font-bold underline text-hyperLink hover:text-blue-800 hover:cursor-pointer visited:text-purple-600'
                            onClick={() => { dispatch(sendVerificationCode(phoneNumber)) }}> {t('resendCode')}</span>
                    </span>
                </div>
            </div>}
        </>
    )
}

export default EnterCode