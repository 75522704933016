import React, {ChangeEvent, useEffect, useState} from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import {useNavigate} from 'react-router-dom'
import sjCircle from '../../assets/images/loginBackground/sj-circle.png'
import { setIsLegalGuardian } from '../../store/actions/signupData.action'
import { StateParams } from '../../store/reducers'

enum NoteHandler {
    IS_PARENT,
    IS_NOT_PARENT
}
const BelowTwelveNote: React.FC<{}> = () => {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const { t } = useTranslation();
    const { isLegalGuardian } = useSelector((state: StateParams) => state.signup)

    const handleClick = (selected: number) => {
        if(selected === NoteHandler.IS_NOT_PARENT) navigate('/signup/firstName')
        else if(selected === NoteHandler.IS_PARENT && !isLegalGuardian) {
            dispatch(setIsLegalGuardian({ isLegalGuardian: true }))
            navigate('/reasonForVisit')
        } else if(selected === NoteHandler.IS_PARENT && isLegalGuardian) {
            navigate('/reasonForVisit')
        }
    }

    return (
        <div className='h-screen max-w-[1240px] px-3 bg-sky-light-lg bg-no-repeat bg-cover'>
            <div className='w-full h-32 flex items-center justify-center'>
                <img src={sjCircle} className='h-[200px] mt-10' />
            </div>
            <div className='mt-16'>
                <p data-test="note-below12-title" className='text-md font-bold text-center'>{t('belowTwelveNote')}</p>
            </div>
            <div className='mt-3'>
                <button data-test="note-below12-parent" className='bg-orange text-white font-bold p-2 rounded-xl w-full my-2' type='submit' onClick={() => handleClick(NoteHandler.IS_PARENT)}>
                {t('parent')}
                </button>
            </div>
            <div className='mt-1'>
                <button data-test="note-below12-not-parent" className='bg-white text-orange border-orange border-2 font-bold p-2 rounded-xl w-full my-2' type='submit' onClick={() => handleClick(NoteHandler.IS_NOT_PARENT)}>
                {t('notParent')}
                </button>
            </div>
        </div>
    )
}

export default BelowTwelveNote