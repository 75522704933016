import React, { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { patientScreenerStart } from "../../store/actions/account.action";
import { StateParams } from "../../store/reducers";
import {MixpanelContext} from "../../analytics/Mixpanel";

interface Value {
    patientId: string
}

export const ViewPatientDetailsAction: React.FC<{ value: Value }> = ({ value }) => {
    const navigate = useNavigate()

    const goToDetails = useCallback(() => {
        navigate(`/patients/${value.patientId}` )
    }, [value])

    return (
        <button className="items-center px-2 py-2 border text-sm font-medium rounded-md bg-white hover:bg-gray-50 first-letter:rounded-l-md border-orange text-orange"
                onClick={goToDetails} >
            View
        </button>
    );
}
