import React from 'react'
import { useState } from 'react';
import { IDebug } from '../../models';
import { debugGet } from '../../services/debug.service'

const Debug = () => {
    const [data, setData] = useState<IDebug>()

    const debugData = () => {
        debugGet()
            .then((response: IDebug) => {
                setData(response)
            }).catch((e) => {
                throw "Failed fetching data"
            });
    }

    return (
        <>
            <button type='button' className="text-white bg-gradient-to-br from-green-400 to-blue-600 hover:bg-gradient-to-bl focus:ring-4 focus:outline-none focus:ring-green-200 dark:focus:ring-green-800 font-medium rounded-lg text-sm px-5 py-2.5 text-center mr-2 mb-2" onClick={debugData}>Button</button>
            {data ? `message: ${data?.message}, status: ${data?.statusCode}` : null}
        </>
    )
}

export default Debug